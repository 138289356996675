<section class="footer-widget">
  <div class="row text-center">
      <div class="col-sm-4">
          <img style="cursor: pointer;" routerLink="/" src="/assets/images/logo_transparent.svg" class="logo-footer" alt="" width="170">
      </div>
      <!-- <div class="col-sm-2 big-screens">
          <h5>Fleet</h5>
      </div> -->
      <div class="col-sm-4 big-screens">
        <a routerLink="/contact-us"><h5>Contact Us</h5></a>
          <ul class="list-group list-group-flush">
              <!-- <li class="list-group-item"><a href="">Our Products</a></li> -->
              <!-- <li class="list-group-item"><a routerLink="/fleet/home">Our Fleet</a></li> -->
              <!-- <li class="list-group-item"></li> -->
          </ul>
      </div>
      <div class="col-sm-12 further-col">
          <!-- <div class="col-sm-3 small-screens">
              <h5>Fleet</h5>
              <ul class="list-group list-group-flush">
                  <li class="list-group-item"><a href="">Fleet</a></li>
              </ul>
          </div> -->
          <div class="col-sm-12 small-screens">
              <a routerLink="/contact-us"><h5>Contact Us</h5></a>
              <ul class="list-group list-group-flush">
                <!-- <li class="list-group-item"><a href="">Our Products</a></li> -->
                <!-- <li class="list-group-item"><a routerLink="/fleet/home">Our Fleet</a></li> -->
                <!-- <li class="list-group-item"></li> -->
              </ul>
          </div>
      </div>
      <br>
      <div class="col-sm-3 last-col">
          <div class="country">
              <em class="fas fa-city"></em> England
              <address class="mt-2">Crown House, 27 Old Gloucester Street, London, United Kingdom, WC1N 3AX</address>
          </div>
          <div class="social-icons d-flex justify-content-between mt-2">
              <!-- <a href="">
                  <em class="fab fa-facebook-f"></em>
              </a>
              <a href="">
                  <em class="fab fa-twitter"></em>
              </a>
              <a href="">
                  <em class="fab fa-instagram"></em>
              </a>
              <a href="">
                  <em class="fab fa-youtube"></em>
              </a> -->
          </div>
      </div>
  </div>
</section>

<footer>
  <div class="d-flex justify-content-around">
      <p>©2021 CabScanner, All Rights Reserved.</p>
      <p>
          <a routerLink="/terms-and-conditions">
              Terms & Conditions
          </a>
          <a routerLink="/privacy-policy">
              Privacy Policy
          </a>
      </p>
  </div>
</footer>
