class Constants {

  private _mapiKey = "Lc6arYkcfU";

  private _angularHost = "http://localhost:4200";
  private _mapiDirectionsHost = `https://mapi.slidebasehq.com`;
  private _mapiDirectionsHostNew = `https://maps.cabscannerhq.com`;

  private _localHost = "http://localhost:8002";
  private _localMqtt = '3.9.124.211';
  private _localMqttSsl = false;

  private _stgHost = "https://stgapi.cabscannerhq.com";
  private _stgMqtt = '3.9.124.211';
  private _stgMqttSsl = true;

  private _uatHost = "https://uatapi.cabscannerhq.com";
  private _uatMqtt = '3.11.7.47';
  private _uatMqttSsl = false;

  private _productionHost = "https://api.cabscannerhq.com";
  private _productionMqtt = 'mqtt.cabscannerhq.com';
  private _productionMqttSsl = true;


  
  // 192.168.1.252
  private _stripeHostUrl = "http://192.168.1.252:8001";
  private _hostUrl = this._productionHost;
  private _mqttAddress = this._productionMqtt;
  private _mqttPortSsl = this._productionMqttSsl;

  // private _fleetNodeUrl = "http://localhost:3998";
  private _fleetNodeUrl = "http://3.9.124.211:3998";

  private _rules = {
    length: {
      email: {
        min: 0,
        max: 50
      },
      addrName: {
        min: 3,
        max: 80
      },
      password: {
        min: 6,
        max: 35
      },
      firstName: {
        min: 5,
        max: 30
      },
      lastName: {
        min: 5,
        max: 30
      },
      pin: {
        min: 4,
        max: 4
      },
      phone: {
        min: 10,
        max: 10
      },
      topup: {
        min: 5,
        max: 1000
      },
      password_recover: {
        min: 6,
        max: 6
      }
    }
  };

  private _mapiGeocode = `${this._mapiDirectionsHostNew}/api/maps/geocode`;
  private _mapiDistance = `${this._mapiDirectionsHostNew}/api/maps/directions`;
  private _register = `${this._hostUrl}/api/register/customer`;
  private _login = `${this._hostUrl}/api/authenticate`;
  private _profile = `${this._hostUrl}/api/customers/account`;
  private _saveFcmToken = `${this._hostUrl}/api/fcm-tokens`;
  private _updateProfile = `${this._hostUrl}/api/customers`;
  private _updateProfilePic = `${this._hostUrl}/api/userProfilePic`;
  private _sendVerificationCode = `${this._hostUrl}/api/signup/sendCode`;
  private _verifyCode = `${this._hostUrl}/api/signup/verifyCode`;
  private _resendCode = `${this._hostUrl}/api/signup/resendCode`;
  private _forgotPassword = `${this._hostUrl}/api/account/reset-password/init`;
  private _recoverPassword = `${this._hostUrl}/api/account/reset-password/finish`;
  private _quotes = `${this._hostUrl}/api/ride-requests/quotes`;
  private _rideRequest = `${this._hostUrl}/api/ride-requests/customer`;
  private _cancelRideRequest = `${this._hostUrl}/api/ride-requests/cancel`;
  private _ongoingRides = `${this._hostUrl}/api/customers/+/ongoing-rides`;
  private _ridesHistory = `${this._hostUrl}/api/customers/+/past-rides`;
  private _updatePassword = `${this._hostUrl}/api/account/change-password`;
  private _addresses = `${this._hostUrl}/api/addresses`;
  private _recentAddresses = `${this._hostUrl}/api/customers/recent-addresses/+`;
  private _deleteAddress = `${this._hostUrl}/api/addresses/+`;
  private _addAddress = `${this._hostUrl}/api/addresses`;
  private _sendRefEmail = `${this._hostUrl}/api/addresses/+`;
  private _customerReceipt = `${this._hostUrl}/api/customer-receipts/`;
  private _systemConfigs = `${this._hostUrl}/api/system-configuration`;
  private _getRide = `${this._hostUrl}/api/ride-requests/`;
  private _getRating = `${this._hostUrl}/api/customer-ratings/+`;
  private _submitRating = `${this._hostUrl}/api/customer-ratings/`;
  private _getDriver = `${this._hostUrl}/api/drivers/`;
  private _getVehicle = `${this._hostUrl}/api/vehicles/`;
  private _getWalletTransaction = `${this._hostUrl}/api/wallet-transactions/`;
  private _resendActivationEmailByPhone = `${this._hostUrl}/api/signup/resend-activation-email/`;
  private _resendActivationEmail = `${this._hostUrl}/api/signup/resend-activation-email-by-email/`;
  private _flightInfo = `${this._hostUrl}/api/ride-requests/getFlightInformation`;
  private _cancelRideReasons = `${this._hostUrl}/api/ride-requests/getRideCancelReasons`;
  private _cancelWithReasons = `${this._hostUrl}/api/ride-requests/cancelWithReasons`;

  private _httpMsgs = `${this._hostUrl}/api/customHttpCodes`;

  private _addPaymentMethod = `${this._hostUrl}/api/payment-methods`;
  private _getPaymentMethods = `${this._hostUrl}/api/payment-methods`;
  private _braintreeClientToken = `${this._hostUrl}/api/payment-methods/+/braintree-token`;
  private _setDefaultPaymentMethod = `${this._hostUrl}/api/payment-methods/+/default?isDefault=true`;
  private _topup = `${this._hostUrl}/api/customers/topup`;
  private _stripeSession = `${this._hostUrl}/api/customers/create-stripe-session`;
  private _accountBalance = `${this._hostUrl}/api/customers/+/balance`;
  // stripe token creation
  private _stripeTokenCreation = `${this._hostUrl}/api/Payments/Stripe/createCustomer`;
  private _addCard = `${this._hostUrl}/api/Payments/Stripe/Cards/save`;
  private _allCard = `${this._hostUrl}/api/Payments/Stripe/Cards/List`;
  private _deleteCard = `${this._hostUrl}/api/Payments/Stripe/Cards/delete`;
  private _setDefaultCard = `${this._hostUrl}/api/Payments/Stripe/Cards/defaultSource`;
  private _walletTopup = `${this._hostUrl}/api/Payments/Stripe/ride-request/walletTopup`;
  private _walletBalance = `${this._hostUrl}/api/customers/`;

  // Geofencing
  private _saveGeofencing = `${this._hostUrl}/api/geofences`;
  private _getGeofencing = `${this._hostUrl}/api/geofences`;

  private _topicRideAccept = `cabscanner/customers/+/riderequest`;
  private _topicRideStatus = `cabscanner/riderequests/+/updates`;
  private _topicRideProgress = `cabscanner/riderequests/+/progress`;
  private _topicDriverLocation = `cabscanner/riderequests/+/driverlocation`;

  // STRIPE
  // private _getStripeAuth = `${this._hostUrl}/api/customers/stripeAuth`;
  // private _stripeWalletTopup = `${this._hostUrl}/api/customers/topup`;

  private _createStripeCustomer = `${this._stripeHostUrl}/api/StripePayment/customer/create`;
  private _setDefaultSource = `${this._stripeHostUrl}/api/StripePayment/customer/setDefaultSource`;
  private _listPaymentMethods = `${this._stripeHostUrl}/api/StripePayment/paymentMethod/list/`;
  private _deletePaymentMethod = `${this._stripeHostUrl}/api/StripePayment/paymentMethod/delete`;
  private _generateClientSecret = `${this._stripeHostUrl}/api/StripePayment/clientSecret`;

  private _updateTopup = `${this._hostUrl}/api/StripePayment/customer/updateTopup`;

  // Fleet Management (NodeJS)
  private _getFleet = `${this._fleetNodeUrl}/fleet`;
  private _fleetSignup = `${this._fleetNodeUrl}/fleet/signUp`;
  private _saveCompanyInfo = `${this._fleetNodeUrl}/fleet/companyInformation`;
  private _saveDocuments = `${this._fleetNodeUrl}/fleet/saveDocuments`;
  private _contactUs = `${this._fleetNodeUrl}/fleet/contactUs`;

  // Judo
  private _judoPaymentSession = `https://api-sandbox.judopay.com/webpayments/payments`;

  public get updateTopup() {
    return this._updateTopup;
  }

  public get judoPaymentSession() {
    return this._judoPaymentSession;
  }

  public get createStripeCustomer() {
    return this._createStripeCustomer;
  }

  public get setDefaultSource() {
    return this._setDefaultSource;
  }

  public get listPaymentMethods() {
    return this._listPaymentMethods;
  }

  public get deletePaymentMethod() {
    return this._deletePaymentMethod;
  }

  public get generateClientSecret() {
    return this._generateClientSecret;
  }

  // public get stripeWalletTopup() {
  //   return this._stripeWalletTopup;
  // }

  // public get getStripeAuth() {
  //   return this._getStripeAuth;
  // }

  public get getWalletTransaction() {
    return this._getWalletTransaction;
  }

  public get resendActivationEmail() {
    return this._resendActivationEmail;
  }

  public get resendActivationEmailByPhone() {
    return this._resendActivationEmailByPhone;
  }

  public get getVehicle() {
    return this._getVehicle;
  }

  public get getDriver() {
    return this._getDriver;
  }

  public get getRide() {
    return this._getRide;
  }

  public get getRating() {
    return this._getRating;
  }

  public get submitRating() {
    return this._submitRating;
  }

  public get customerReceipt() {
    return this._customerReceipt;
  }

  public get systemConfigs() {
    return this._systemConfigs;
  }

  public get angularHost() {
    return this._angularHost;
  }

  public get deleteAddress() {
    return this._deleteAddress;
  }

  public get addresses() {
    return this._addresses;
  }

  public get recentAddresses() {
    return this._recentAddresses;
  }

  public get cancelRideRequest() {
    return this._cancelRideRequest;
  }

  public get ridesHistory() {
    return this._ridesHistory;
  }

  public get ongoingRides() {
    return this._ongoingRides;
  }

  public get topicRideStatus() {
    return this._topicRideStatus;
  }

  public get topicRideProgress() {
    return this._topicRideProgress;
  }

  public get topicDriverLocation() {
    return this._topicDriverLocation;
  }

  public get topicRideAccept() {
    return this._topicRideAccept;
  }

  public get mapiDistance() {
    return this._mapiDistance;
  }

  public get mapiKey() {
    return this._mapiKey;
  }

  public get mapiGeocode() {
    return this._mapiGeocode;
  }

  public get cancelRideReasons() {
    return this._cancelRideReasons;
  }
  public get cancelWithReasons() {
    return this._cancelWithReasons;
  }
  public get httpMsgs() {
    return this._httpMsgs;
  }

  public get recoverPassword() {
    return this._recoverPassword;
  }

  public get register() {
    return this._register;
  }

  public get forgotPassword() {
    return this._forgotPassword;
  }

  public get resendCode() {
    return this._resendCode;
  }

  public get verifyCode() {
    return this._verifyCode;
  }

  public get sendVerificationCode() {
    return this._sendVerificationCode;
  }

  public get login(): String {
    return this._login;
  }

  public get quotes(): String {
    return this._quotes;
  }

  public get fleetSignup(): String {
    return this._fleetSignup;
  }

  public get getFleet(): String {
    return this._getFleet;
  }

  public get saveCompanyInfo(): String {
    return this._saveCompanyInfo;
  }

  public get saveDocuments(): String {
    return this._saveDocuments;
  }

  public get contactUs(): String {
    return this._contactUs;
  }

  public get rideRequest(): String {
    return this._rideRequest;
  }

  public get profile(): String {
    return this._profile;
  }

  public get saveFcmToken(): String {
    return this._saveFcmToken;
  }

  public get updateProfile(): String {
    return this._updateProfile;
  }

  public get updatePassword(): String {
    return this._updatePassword;
  }

  public get sendRefEmail(): String {
    return this._sendRefEmail;
  }

  public get addAddress(): String {
    return this._addAddress;
  }

  public get addPaymentMethod(): String {
    return this._addPaymentMethod;
  }

  public get getPaymentMethods(): String {
    return this._getPaymentMethods;
  }

  public get setDefaultPaymentMethod(): String {
    return this._setDefaultPaymentMethod;
  }

  public get topup(): String {
    return this._topup;
  }

  public get stripeSession(): String {
    return this._stripeSession;
  }
  // Stripe Token Create
  // public get stripeTokenCreate(): String {
  //   return this._stripeTokenCreation;
  // }

  public get stripeAddCard(): String {
    return this._addCard;
  }

  public get getAllCard(): String {
    return this._allCard;
  }

  public get deleteCard(): String {
    return this._deleteCard;
  }

  public get setDefaultCard(): String {
    return this._setDefaultCard;
  }

  public get braintreeClientToken(): String {
    return this._braintreeClientToken;
  }

  public get walletTopup(): String {
    return this._walletTopup;
  }

  public get walletBalance(): String {
    return this._walletBalance;
  }

  public get getFlightInformation(): String {
    return this._flightInfo;
  }
  public get accountBalance(): String {
    return this._accountBalance;
  }

  public get updateProfilePic(): String {
    return this._updateProfilePic;
  }

  public get mqttAddress(): String {
    return this._mqttAddress;
  }

  public get mqttPortSsl(): Boolean {
    return this._mqttPortSsl;
  }

  public get getRules() {
    return this._rules;
  }

  public get saveGeofencing(): String {
    return this._saveGeofencing;
  }

  public get getGeofencing(): String {
    return this._getGeofencing;
  }

}

export default Constants;
