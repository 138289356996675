<ng-http-loader></ng-http-loader>
<app-navbar *ngIf="!mobileOnly"></app-navbar>
<app-sidebar></app-sidebar>
<div class="middle-section">
  <router-outlet></router-outlet>
  <div id="generalNotification" class="notification-popup">
    <em id="notificationIcon" class="fa"></em>&nbsp;<strong
      id="notificaitonHeader"
    ></strong>
    <p id="notificationMessage"></p>
  </div>
</div>
<app-footer *ngIf="!mobileOnly"></app-footer>
