<section>
  <div class="row">
    <div class="col-md-6">
      <div class="box">
        <!-- <p class="verification-email">A verification email has been sent to sarmadstudio.me@gmail.com. Please Active Your account before login.</p> -->
        <h3 class="text-center">Customize <span>CabScanner</span> Messages</h3>
        <form>
          <div class="form-group">
            <select (change)="getCustomHttpMsg()" name="httpStatus" class="form-control" [(ngModel)]="customMsg.httpStatus">
              <option value="1" disabled selected>Select HTTP Status</option>
              <option [value]="item.id" *ngFor="let item of httpStatusEnum">{{ item.value }}</option>
            </select>
          </div>
          <div class="form-group">
            <input [(ngModel)]="customMsg.responseTitle" name="responseTitle" class="form-control formFields app-inputfield" placeholder="Response Title*">
          </div>
          <div class="form-group">
            <textarea rows="5" cols="5" [(ngModel)]="customMsg.responseMessage" name="responseMessage" class="form-control" placeholder="Response Message*"></textarea>
          </div>
          <div class="clearfix"></div>
          <div class="form-group">
            <button type="submit" [disabled]="sendingRequest" (click)="saveMsg()" class="primary-btn w-100">{{ btnTxt }}</button>
          </div>
        </form>
      </div>
    </div>
  </div>
</section>
