import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { LocalStorageService } from 'ngx-localstorage';
import { BehaviorSubject, Observable } from 'rxjs';
import Constants from '../classes/Constants';
import { AddressInterface } from '../interfaces/AddressInterface';
import { AuthInterface } from '../interfaces/AuthInterface';
import { UserInterface } from '../interfaces/UserInterface';
import { GlobalService } from './global.service';

@Injectable({
  providedIn: 'root'
})
export class AccountService {

  public endPoints: Constants;

  private isLoginBroadcast = new BehaviorSubject(false);
  isLogin = this.isLoginBroadcast.asObservable();

  constructor(private http: HttpClient, private consts: Constants, private localStorage: LocalStorageService) {
    this.endPoints = consts;
  }

  sendVerificationCode(phone): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders().set('Content-Type', 'application/json'),
    };
    return this.http.post<any>(`${this.endPoints.sendVerificationCode}`, { phoneNumber: phone }, httpOptions);
  }

  verifyCode(phone, code): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders().set('Content-Type', 'application/json'),
    };
    return this.http.post<any>(`${this.endPoints.verifyCode}`, { verificationCode: code, phoneNumber: phone }, httpOptions);
  }

  resendCode(phone): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders().set('Content-Type', 'application/json'),
    };
    return this.http.post<any>(`${this.endPoints.resendCode}`, { phoneNumber: phone }, httpOptions);
  }

  resendActivationEmail(email): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders().set('Content-Type', 'application/json'),
    };
    return this.http.post<any>(`${this.endPoints.resendActivationEmail}?email=${email}`, null, httpOptions);
  }

  resendActivationEmailByPhone(phone): Observable<String> {
    const httpOptions = {
      headers: new HttpHeaders().set('Content-Type', 'application/json'),
    };
    return this.http.post<String>(`${this.endPoints.resendActivationEmailByPhone}`, {
      alreadyActivated: null,
      alreadyVerified: null,
      phoneNumber: phone,
      verificationCode: null
    }, httpOptions);
  }

  register(payload): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders().set('Content-Type', 'application/json'),
    };
    return this.http.post<any>(`${this.endPoints.register}`, payload, httpOptions);
  }

  login(email, password): Observable<AuthInterface> {
    const httpOptions = {
      headers: new HttpHeaders().set('Content-Type', 'application/json'),
    };
    return this.http.post<AuthInterface>(`${this.endPoints.login}`, { username: email, password: password }, httpOptions);
  }

  forgotPassword(email): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders().set('Content-Type', 'application/json'),
    };
    return this.http.post<any>(`${this.endPoints.forgotPassword}/${email}`, null, httpOptions);
  }

  recoverPassword(code, password): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders().set('Content-Type', 'application/json'),
    };
    return this.http.post<any>(`${this.endPoints.recoverPassword}`, { key: code, newPassword: password }, httpOptions);
  }

  profile(): Observable<UserInterface> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${this.localStorage.get("auth").id_token}`
      })
    };
    return this.http.get<UserInterface>(`${this.endPoints.profile}`, httpOptions);
  }

  saveFcmToken(token): Promise<UserInterface> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${this.localStorage.get("auth").id_token}`
      })
    };
    return this.http.post<UserInterface>(`${this.endPoints.saveFcmToken}`, { token: token, userId: this.localStorage.get("auth").user.userId }, httpOptions).toPromise();
  }

  getAddresses(): Observable<AddressInterface[]> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${this.localStorage.get("auth").id_token}`
      })
    };
    // this.localStorage.get("auth").user.id)
    return this.http.get<AddressInterface[]>(`${this.endPoints.addresses}?customerId.equals=${this.localStorage.get("auth").user.id}`, httpOptions);
  }

  deleteAddress(id): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${this.localStorage.get("auth").id_token}`
      })
    };
    // this.localStorage.get("auth").user.id)
    return this.http.delete<any>(`${this.endPoints.deleteAddress.replace("+", id)}`, httpOptions);
  }

  updateProfile(payload): Observable<UserInterface> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${this.localStorage.get("auth").id_token}`
      })
    };
    return this.http.put<UserInterface>(`${this.endPoints.updateProfile}`, payload, httpOptions);
  }

  updateProfilePicture(payload): Observable<UserInterface> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${this.localStorage.get("auth").id_token}`
      })
    };
    return this.http.put<UserInterface>(`${this.endPoints.updateProfilePic}`, payload, httpOptions);
  }

  sendRefEmail(email): Observable<UserInterface> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${this.localStorage.get("auth").id_token}`
      })
    };
    return this.http.put<UserInterface>(`${this.endPoints.sendRefEmail}`, { email: email }, httpOptions);
  }

  updatePassword(payload): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${this.localStorage.get("auth").id_token}`
      })
    };
    return this.http.post<any>(`${this.endPoints.updatePassword}`, payload, httpOptions);
  }

  addAddress(addressObj): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${this.localStorage.get("auth").id_token}`
      })
    };
    return this.http.post<any>(`${this.endPoints.addAddress}`, addressObj, httpOptions);
  }

  addPaymentMethod(paymentObj): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${this.localStorage.get("auth").id_token}`
      })
    };
    return this.http.post<any>(`${this.endPoints.addPaymentMethod}`, paymentObj, httpOptions);
  }

  getPaymentMethods(): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${this.localStorage.get("auth").id_token}`
      })
    };
    return this.http.get<any>(`${this.endPoints.getPaymentMethods}?customerId.equals=${this.localStorage.get("auth").user.id}`, httpOptions);
  }

  setDefaultPaymentMethod(paymentMethodId): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${this.localStorage.get("auth").id_token}`
      })
    };
    return this.http.put<any>(`${this.endPoints.setDefaultPaymentMethod.replace("+", paymentMethodId)}`, null, httpOptions);
  }

  getStripeSession(obj): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${this.localStorage.get("auth").id_token}`
      })
    };

    return this.http.post<any>(`${this.endPoints.stripeSession}`, obj, httpOptions);
  }

  // CreateStripeToken(): Observable<any> {
  //   const httpOptions = {
  //     headers: new HttpHeaders({
  //       'Content-Type': 'application/json',
  //       'Authorization': `Bearer ${this.localStorage.get("auth").id_token}`
  //     })
  //   };

  //   return this.http.get<any>(`${this.endPoints.stripeTokenCreate}?name=${this.localStorage.get("auth").user.firstName}`, httpOptions);
  // }

  topup(addTopupObj): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${this.localStorage.get("auth").id_token}`
      })
    };

    return this.http.post<any>(`${this.endPoints.topup}`, addTopupObj, httpOptions);
  }

  wallettopupAmount(obj): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${this.localStorage.get("auth").id_token}`
      })
    };
    return this.http.post<any>(`${this.endPoints.walletTopup}`, obj, httpOptions);
  }

  walletBalance(): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${this.localStorage.get("auth").id_token}`
      })
    };
    return this.http.get<any>(`${this.endPoints.walletBalance}${this.localStorage.get("auth").user.id}/balance`, httpOptions);
  }

  generateClientTokenBt(): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${this.localStorage.get("auth").id_token}`
      })
    };

    return this.http.get<any>(`${this.endPoints.braintreeClientToken.replace("+", this.localStorage.get("auth").user.id)}`, httpOptions);
  }

  getAccountBalance(): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${this.localStorage.get("auth").id_token}`
      })
    };

    return this.http.get<any>(`${this.endPoints.accountBalance.replace("+", this.localStorage.get("auth").user.id)}`, httpOptions);
  }

  isAuthenticated() {
    if (this.localStorage.get('auth') && this.localStorage.get('auth').id_token)
      return true;

    return false;
  }

  setIsLogin(status) {
    if (!status)
      this.localStorage.remove("auth")
    this.isLoginBroadcast.next(status);
  }
}
