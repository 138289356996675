<!-- Sidebar Mobile Menu -->
<div class="sidebar-menu" id="sidebar-menu">
  <div class="close-icon">
    <a (click)="gs.closeSidebar()"><em class="fas fa-times"></em></a>
  </div>
  <div class="d-flex flex-column justify-content-center h-100 inner-sidemenu">
    <ul class="">
      <li *ngIf="isLogin">
      <a class="dropdown profile-dropdown navbar-main-items">
        <img [src]="user.imageUrl && user.imageUrl != 'string' ? user.imageUrl : '/assets/images/user.png'"
          class="rounded-circle" alt="" width="40" height="40">
        <span class="ml-2" id="dropdownMenuProfile" data-toggle="dropdown" aria-haspopup="true"
          aria-expanded="false"><span
            class="user-name">{{ this.user && this.user.firstName + (user.lastName && user.lastName != " " && user.lastName != "null" ? " " + user.lastName : "") }}
          </span>
        </span>
      </a>
      </li>
      <li *ngIf="isLogin" (click)="ride()">Ride</li>
      <li (click)="routeTo('/account/login')" *ngIf="!isLogin"><a>Login</a></li>
      <li *ngIf="!isLogin"><a (click)="routeTo('/account/phone-verification')">Register</a></li>
      <li *ngIf="!isLogin">
        <a (click)="routeTo('/fleet/home')">Fleet</a>
      </li>
      <!-- <li (click)="routeTo('/fleet/driver-register')" *ngIf="!isLogin">Become Driver</li> -->
      <li *ngIf="!isLogin" (click)="routeTo('/city')">Cities</li>
      <li *ngIf="!isLogin" (click)="routeTo('/account/login')">Book Now</li>
      <li *ngIf="isLogin" (click)="routeTo('/?book=now')">Book Now</li>
      <li *ngIf="isLogin" (click)="routeTo('/account/profile')">Profile</li>
      <li *ngIf="isLogin" (click)="logout()">Logout</li>
    </ul>
  </div>
</div>
