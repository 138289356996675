<div class="contactus-main">
    <div class="row">
        <div class="col-xl-8 col-md-9 m-auto">
            <div class="box">
                <div class="inner-box">
                    <div class="left-area w-50">
                        <div class="heading">
                            <h2>Say hello to us!</h2>
                            <small>Got to know something? Let's talk about it.<br>We would love to hear from you!</small>
                        </div>
                        <div class="address-details">
                            <h6><span>Det</span>ails</h6>
                            <div class="details-inner">
                                <h5>Address</h5>
                                <address class="info pr-5">Crown House, 27 Old Gloucester Street, London, United Kingdom, WC1N 3AX.</address>
                            </div>
                            <div class="details-inner">
                                <h5>Email</h5>
                                <a href="mailto:support@cabscannerhq.com" class="info">support@cabscannerhq.com</a>
                            </div>
                        </div>
                    </div>
                    <div class="right-area w-50">
                        <h5><span>Send</span> a <strong>message</strong></h5>
                        <div class="form-area">
                            <form action="">
                                <div class="form-group">
                                    <input
                                      (input)="_gs.inputValidations($event, { required: true, max: 100, min: _const.getRules.length.firstName.min, pattern: 'alpha', restrict: true })"
                                      (blur)="_gs.inputValidations($event, { required: true, max: 100, min: _const.getRules.length.firstName.min, pattern: 'alpha', restrict: true })"
                                      type="text" name="name" class="form-control app-inputfield" id="name" placeholder="Full Name*">
                                </div>
                                <div class="form-group">
                                    <input
                                      (input)="_gs.inputValidations($event, { required: true, email: true, max: _const.getRules.length.email.max })"
                                      (blur)="_gs.inputValidations($event, { required: true, email: true, max: _const.getRules.length.email.max })"
                                      [attr.maxlength]="_const.getRules.length.email.max"
                                      type="email" name="email" style="text-transform: none;" class="form-control app-inputfield" id="email" placeholder="Email Address*">
                                </div>
                                <div class="form-group">
                                    <textarea
                                    (input)="_gs.inputValidations($event, { required: true, max: 300, min: 20, restrict: true })"
                                    (blur)="_gs.inputValidations($event, { required: true, max: 300, min: 20, restrict: true })" id="message" class="form-control" name="" rows="5" placeholder="Message*"></textarea>
                                </div>
                                <div class="form-group">
                                    <button type="button" [disabled]="sendingReq" (click)="contactUs()" class="dark-btn w-100">{{ sendingReq ? 'Sending..' : 'Submit' }}</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
