// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  url: 'http://localhost:4200',
  firebaseConfig: {
    apiKey: "AIzaSyD42DzCQnfA0ZSdCYex12COu9veEGEsc98",
    authDomain: "cabscanner-70a49.firebaseapp.com",
    projectId: "cabscanner-70a49",
    storageBucket: "cabscanner-70a49.appspot.com",
    messagingSenderId: "658804296117",
    appId: "1:658804296117:web:032d83c4ecd6974742b1fc",
    measurementId: "G-EB4YS8G9NY"

    // MOT
    // apiKey: "AIzaSyDtDsDwIFQ9d1kfM6sLAkPnM9YfkfRzZ2Q",
    // authDomain: "mot-scanner-ec18f.firebaseapp.com",
    // projectId: "mot-scanner-ec18f",
    // storageBucket: "mot-scanner-ec18f.appspot.com",
    // messagingSenderId: "590481393719",
    // appId: "1:590481393719:web:e3b0c0aa53697fef7917b2",
    // measurementId: "G-WY40TH1YHH"
  },
  
  stripPublishedKey: "pk_test_51IqCQPKUQyQSdHmouvzKE7Sp5znTL2K8ptNpajq9NB3VqgkzkeNetwnj1S4ESEE8YuYLYWw2wDYZCeHFiijEvonN00vzdkhV7n"
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
