import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-geo-fence',
  templateUrl: './geo-fence.component.html',
  styleUrls: ['./geo-fence.component.scss']
})
export class GeoFenceComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
