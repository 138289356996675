import { Injectable } from '@angular/core';
import { BehaviorSubject, ReplaySubject } from 'rxjs';
import * as Paho from "src/assets/mqtt/paho-mqtt.js";
import Constants from '../classes/Constants';

@Injectable({
  providedIn: 'root'
})
export class EventMqttService {
  client: any;
  isConnected: boolean = false;
  onMessageReceived = new ReplaySubject<any>(1);

  private rideAcceptSubscriberBroadcaster = new BehaviorSubject(false);
  rideAcceptEventSubscribed = this.rideAcceptSubscriberBroadcaster.asObservable();

  private mqttConnectedBroadcast = new BehaviorSubject(false);
  mqttConnected = this.mqttConnectedBroadcast.asObservable();

  currentlyActivateRideRequestId = null;

  constructor(private _const: Constants) {
  }

  setActiveRRId(id) {
    this.currentlyActivateRideRequestId = id;
  }

  rideAcceptSubscribed(isSubscribed) {
    this.rideAcceptSubscriberBroadcaster.next(isSubscribed);
  }

  connect() {
    this.client = new Paho.Client(this._const.mqttAddress, this._const.mqttPortSsl ? 8084 : 8083, '');
    // set callback handlers
    this.client.onConnectionLost = (responseObject) => {
      if (responseObject.errorCode !== 0) {
        console.log("onConnectionLost:" + responseObject.errorMessage);
      }
      this.client.reconnect = true;
      this.client.reconnectInterval = 10;
    };

    this.client.onMessageArrived = (message) => {
      this.onMessageReceived.next(message);
    };
    // connect the client
    this.client.connect({
      userName: "admin",
      password: "5l!de6@$euk",
      useSSL: this._const.mqttPortSsl,
      onSuccess: this.onConnect.bind(this),
      onFailure: this.onError.bind(this),
    });
  }

  onError(e) {
  }

  // called when the client connects
  onConnect() {
    // Once a connection has been made, make a subscription and send a message.
    this.isConnected = this.client.isConnected();
    this.mqttConnectedBroadcast.next(true)
    console.log("MQTT Connected");
  }

  subscribeTopic(topic: string) {
    if (this.client != null && this.client.isConnected())
      this.client.subscribe(topic);
  }

  unSubscribeTopic(topic: string) {
    if (this.client != null && this.client.isConnected()) {
      this.client.unsubscribe(topic);
    }
  }

  sendMessage(topic: string, message: string, qos: number) {
    if (this.client != null && this.client.isConnected()) {
      var payloadMessage = new Paho.Message(message);
      payloadMessage.destinationName = topic;
      payloadMessage.qos = qos;
      this.client.send(payloadMessage);
    }
  }

}
