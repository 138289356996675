<div class="find-city">
  <div class="main-header">
      <div class="header-inner row h-100 d-flex justify-content-between align-items-center flex-wrap">
          <div class="col-lg-6 d-flex align-items-center justify-content-center first-col">
              <div class="heading">
                  <h3>Find a City</h3>
                  <small>We are available in 150+ Countries. Find us below.</small>
                  <div class="form-area">
                      <form action="">
                          <div class="form-group">
                              <span><em class="fas fa-search"></em></span>
                              <input type="text" name="" id="" class="w-100" placeholder="Search 150+ Cities Here...">
                          </div>
                      </form>
                  </div>
              </div>
          </div>
          <div class="col-lg-6 second-col">
              <div class="image">
                  <img src="../../../assets/images/find_city_banner.png" alt="" height="100%">
              </div>
          </div>
      </div>
  </div>
  <div class="countries">
      <div class="countries-list">
          <h5>Europe</h5>
          <div class="inner-countries row">
              <div class="cities col-lg-2 col-md-3 col-sm-6">
                  <div class="state">Czech</div>
                  <div class="city">Boskovice</div>
              </div>
              <div class="cities col-lg-2 col-md-3 col-sm-6">
                  <div class="state">Czech</div>
                  <div class="city">Boskovice</div>
              </div>
              <div class="cities col-lg-2 col-md-3 col-sm-6">
                  <div class="state">Czech</div>
                  <div class="city">Boskovice</div>
              </div>
              <div class="cities col-lg-2 col-md-3 col-sm-6">
                  <div class="state">Czech</div>
                  <div class="city">Boskovice</div>
              </div>
              <div class="cities col-lg-2 col-md-3 col-sm-6">
                  <div class="state">Czech</div>
                  <div class="city">Boskovice</div>
              </div>
              <div class="cities col-lg-2 col-md-3 col-sm-6">
                  <div class="state">Czech</div>
                  <div class="city">Boskovice</div>
              </div>
          </div>
      </div>
      <div class="countries-list">
          <h5>Asia</h5>
          <div class="inner-countries row">
              <div class="cities col-lg-2 col-md-3 col-sm-6">
                  <div class="state">Czech</div>
                  <div class="city">Boskovice</div>
              </div>
              <div class="cities col-lg-2 col-md-3 col-sm-6">
                  <div class="state">Czech</div>
                  <div class="city">Boskovice</div>
              </div>
              <div class="cities col-lg-2 col-md-3 col-sm-6">
                  <div class="state">Czech</div>
                  <div class="city">Boskovice</div>
              </div>
              <div class="cities col-lg-2 col-md-3 col-sm-6">
                  <div class="state">Czech</div>
                  <div class="city">Boskovice</div>
              </div>
              <div class="cities col-lg-2 col-md-3 col-sm-6">
                  <div class="state">Czech</div>
                  <div class="city">Boskovice</div>
              </div>
              <div class="cities col-lg-2 col-md-3 col-sm-6">
                  <div class="state">Czech</div>
                  <div class="city">Boskovice</div>
              </div>
          </div>
      </div>
      <div class="countries-list">
          <h5>North America</h5>
          <div class="inner-countries row">
              <div class="cities col-lg-2 col-md-3 col-sm-6">
                  <div class="state">Czech</div>
                  <div class="city">Boskovice</div>
              </div>
              <div class="cities col-lg-2 col-md-3 col-sm-6">
                  <div class="state">Czech</div>
                  <div class="city">Boskovice</div>
              </div>
              <div class="cities col-lg-2 col-md-3 col-sm-6">
                  <div class="state">Czech</div>
                  <div class="city">Boskovice</div>
              </div>
              <div class="cities col-lg-2 col-md-3 col-sm-6">
                  <div class="state">Czech</div>
                  <div class="city">Boskovice</div>
              </div>
              <div class="cities col-lg-2 col-md-3 col-sm-6">
                  <div class="state">Czech</div>
                  <div class="city">Boskovice</div>
              </div>
              <div class="cities col-lg-2 col-md-3 col-sm-6">
                  <div class="state">Czech</div>
                  <div class="city">Boskovice</div>
              </div>
          </div>
      </div>
  </div>
</div>
