import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { LocalStorageService } from 'ngx-localstorage';
import { Observable, ObservableLike } from 'rxjs';
import Constants from '../classes/Constants';
import { QuotationsInterface } from '../interfaces/QuotationsInterface';
import { GeocodeInterface } from '../interfaces/GeocodeInterface';
import { GlobalService } from './global.service';

@Injectable({
  providedIn: 'root'
})
export class ApisService {

  public endPoints: Constants;

  constructor(private http: HttpClient, private gs: GlobalService, private consts: Constants, private localStorage: LocalStorageService) {
    this.endPoints = consts;
  }

  getGeocodeInformation(lat, lng): Observable<GeocodeInterface> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };
    return this.http.get<GeocodeInterface>(`${this.endPoints.mapiGeocode}?location=${lat},${lng}&application_key=${this.endPoints.mapiKey}&src_ref=portal`, httpOptions);
  }

  getCustomHttpMessages(): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${this.localStorage.get("auth").id_token}`
      })
    };
    return this.http.get<any>(`${this.endPoints.httpMsgs}`, httpOptions);
  }

  saveCustomHttpMessage(msg, op): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${this.localStorage.get("auth").id_token}`
      })
    };
    if (op == "update")
      return this.http.put<any>(`${this.endPoints.httpMsgs}`, msg, httpOptions);
    return this.http.post<any>(`${this.endPoints.httpMsgs}`, msg, httpOptions);
  }

  getGeocodeInformationPromise(lat, lng): Promise<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };
    return this.http.get<any>(`${this.endPoints.mapiGeocode}?location=${lat},${lng}&application_key=${this.endPoints.mapiKey}&src_ref=portal`, httpOptions).toPromise();
  }
  getRideCancelReasons(rideType): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${this.localStorage.get("auth").id_token}`
      })
    };
    return this.http.get<any>(`${this.endPoints.cancelRideReasons}?type=${rideType}`, httpOptions);
  }

  cancelWithReason(id,reasons): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${this.localStorage.get("auth").id_token}`
      })
    };
    return this.http.put<any>(`${this.endPoints.cancelWithReasons}?id=${id}&reasons=${reasons}&rideCancelSource=CUSTOMER`, null, httpOptions);
  }

  postGeocodeInformationPromise(obj): Promise<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };
    return this.http.post<any>(`${this.endPoints.mapiGeocode}`,obj, httpOptions).toPromise();
  }
  getMapiDistance(pLat, pLng, dLat, dLng, pitStops): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };

    let waypointsQuery = '';
    if (pitStops.length)
      waypointsQuery = `&waypoint=${pitStops.map(p => (p.locationCoordinates.latitude + "," + p.locationCoordinates.longitude).trim()).join("|").trim()}`;

    return this.http.get<any>(`${this.endPoints.mapiDistance}?origin=${pLat},${pLng}&destination=${dLat},${dLng}${waypointsQuery}&application_key=${this.endPoints.mapiKey}&src_ref=portal`, httpOptions);
  }

  getQuotes(payload): Observable<QuotationsInterface[]> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${this.localStorage.get("auth").id_token}`
      })
    };
    return this.http.post<QuotationsInterface[]>(`${this.endPoints.quotes}`, payload, httpOptions);
  }

  contactUs(payload): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };
    return this.http.post<any>(`${this.endPoints.contactUs}`, payload, httpOptions);
  }

  saveCompanyInfo(payload): Promise<any[]> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };
    return this.http.post<any[]>(`${this.endPoints.saveCompanyInfo}`, payload, httpOptions).toPromise();
  }

  saveDocuments(payload): Promise<any[]> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };
    return this.http.post<any[]>(`${this.endPoints.saveDocuments}`, payload, httpOptions).toPromise();
  }

  fleetSignup(payload): Promise<any[]> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };
    return this.http.post<any[]>(`${this.endPoints.fleetSignup}`, payload, httpOptions).toPromise();
  }

  getFleet(session): Promise<any[]> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };
    return this.http.get<any[]>(`${this.endPoints.getFleet}?session=${session}`, httpOptions).toPromise();
  }

  rideRequst(payload): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${this.localStorage.get("auth").id_token}`
      })
    };
    return this.http.post<any>(`${this.endPoints.rideRequest}`, payload, httpOptions);
  }

  ongoingRides(): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${this.localStorage.get("auth").id_token}`
      })
    };
    return this.http.get<any>(`${this.endPoints.ongoingRides.replace('+', this.localStorage.get("auth").user.id)}`, httpOptions);
  }

  ridesHistory(): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${this.localStorage.get("auth").id_token}`
      })
    };
    return this.http.get<any>(`${this.endPoints.ridesHistory.replace('+', this.localStorage.get("auth").user.id)}`, httpOptions);
  }

  customerReceipt(receiptId): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${this.localStorage.get("auth").id_token}`
      })
    };
    return this.http.get<any>(`${this.endPoints.customerReceipt}${receiptId}`, httpOptions);
  }
  flightInformation(obj): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${this.localStorage.get("auth").id_token}`
      })
    };
    return this.http.post<any>(`${this.endPoints.getFlightInformation}`,obj, httpOptions);
  }

  getSystemConfigs(): Promise<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${this.localStorage.get("auth").id_token}`
      })
    };
    return this.http.get<any>(`${this.endPoints.systemConfigs}`, httpOptions).toPromise();
  }

  customerReceiptPromise(receiptId): Promise<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${this.localStorage.get("auth").id_token}`
      })
    };
    return this.http.get<any>(`${this.endPoints.customerReceipt}${receiptId}`, httpOptions).toPromise();
  }

  getRide(rideId): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${this.localStorage.get("auth").id_token}`
      })
    };
    return this.http.get<any>(`${this.endPoints.getRide}${rideId}`, httpOptions);
  }

  submitRating(payload): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${this.localStorage.get("auth").id_token}`
      })
    };
    return this.http.post<any>(`${this.endPoints.submitRating}`, payload, httpOptions);
  }

  getRating(rideId): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${this.localStorage.get("auth").id_token}`
      })
    };
    return this.http.get<any>(`${this.endPoints.getRating.replace("+", rideId)}`, httpOptions);
  }

  getDriver(driverId): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${this.localStorage.get("auth").id_token}`
      })
    };
    return this.http.get<any>(`${this.endPoints.getDriver}${driverId}`, httpOptions);
  }

  getVehicle(vehicleId): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${this.localStorage.get("auth").id_token}`
      })
    };
    return this.http.get<any>(`${this.endPoints.getVehicle}${vehicleId}`, httpOptions);
  }

  getWalletTransaction(walletTransactionId): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${this.localStorage.get("auth").id_token}`
      })
    };
    return this.http.get<any>(`${this.endPoints.getWalletTransaction}${(walletTransactionId ? walletTransactionId : '')}`, httpOptions);
  }

  cancelRide(rideRequestId): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${this.localStorage.get("auth").id_token}`
      })
    };
    return this.http.put<any>(`${this.endPoints.cancelRideRequest}?id=${rideRequestId}`, null, httpOptions);
  }

  createStripeCustomer(email): Promise<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };
    return this.http.get<any>(`${this.endPoints.createStripeCustomer}?email=${email}`, httpOptions).toPromise();
  }

  getStripeClientSecret(amount, customerId, pmId = null): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      })
    };
    return this.http.get<any>(`${this.endPoints.generateClientSecret}?amount=${amount}&customerId=${customerId}${pmId ? '&paymentMethodId=' + pmId : ''}`, httpOptions);
  }

  getPaymentMethods(customerId): Promise<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      })
    };
    return this.http.get<any>(`${this.endPoints.listPaymentMethods}?customerId=${customerId}`, httpOptions).toPromise();
  }

  setDefaultSource(customerId, pmId): Promise<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      })
    };
    return this.http.get<any>(`${this.endPoints.setDefaultSource}?customerId=${customerId}&paymentMethodId=${pmId}`, httpOptions).toPromise();
  }

  updatePaymentTopup(payload): Promise<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${this.localStorage.get("auth").id_token}`
      })
    };
    return this.http.post<any>(`${this.endPoints.updateTopup}`, payload, httpOptions).toPromise();
  }

  deletePaymentMethod(paymentMethodId): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      })
    };
    return this.http.delete<any>(`${this.endPoints.deletePaymentMethod}?paymentMethodId=${paymentMethodId}`, httpOptions);
  }

  submitStripeAndTopup(payload): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${this.localStorage.get("auth").id_token}`
      })
    };
    // this.endPoints.stripeWalletTopup
    return this.http.post<any>(``, payload, httpOptions);
  }

  judoPaymentSession(payload): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        'API-Version': '6.6',
        'Content-Type': 'application/json',
      })
    };
    return this.http.post<any>(`${this.endPoints.judoPaymentSession}`, payload, httpOptions);
  }

  saveGeofencing(payload): Promise<any[]> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${this.localStorage.get("auth").id_token}`
      })
    };
    return this.http.post<any[]>(`${this.endPoints.saveGeofencing}`, payload, httpOptions).toPromise();
  }

  updateGeofencing(payload): Promise<any[]> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${this.localStorage.get("auth").id_token}`
      })
    };
    return this.http.put<any[]>(`${this.endPoints.saveGeofencing}`, payload, httpOptions).toPromise();
  }

  deleteGeofence(id): Promise<any[]> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${this.localStorage.get("auth").id_token}`
      })
    };
    return this.http.delete<any[]>(`${this.endPoints.saveGeofencing}/${id}`, httpOptions).toPromise();
  }

  getGeofences(id = null): Promise<any[]> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${this.localStorage.get("auth").id_token}`
      })
    };
    return this.http.get<any[]>(`${this.endPoints.saveGeofencing}?placeType.in=AIRPORT`, httpOptions).toPromise();
  }

}
