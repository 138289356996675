import { UserInterface } from './../../../interfaces/UserInterface';
import { Router } from '@angular/router';
import { GlobalService } from './../../../services/global.service';
import { LocalStorageService } from 'ngx-localstorage';
import { AccountService } from './../../../services/account.service';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit {

  isLogin = false;
  user: UserInterface = null;

  constructor(private _accountS: AccountService, private _ls: LocalStorageService, public gs: GlobalService, private router: Router) { }

  ngOnInit(): void {
    this._accountS.isLogin.subscribe(x => {
      this.user = this._ls.get("auth") ? this._ls.get("auth").user : null;
      this.isLogin = x
    });
  }

  routeTo(route) {
    this.gs.closeSidebar();
    this.router.navigateByUrl(route);
  }

  ride() {
    this.gs.closeSidebar();
    this.router.navigateByUrl('/?book=now')
  }

  logout() {
    this.gs.closeSidebar();
    this.gs.logout();
  }

}
