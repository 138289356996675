<!-- Home Page Navbar -->
<header *ngIf="!isLogin && hideTopbar == 'false'" class="sticky-top">
  <nav class="navbar lg-screen">
    <div class="d-flex align-items-center left-menu">
      <a class="navbar-brand">
        <img routerLink="/" src="/assets/images/Logo.svg" class="d-inline-block align-top"
          alt="Cab Scanner Logo">
      </a>
      <div class="menu-items">
        <!-- <a routerLink="/fleet/driver-register">Become Driver</a> -->
        <a routerLink="/fleet/home">Fleet</a>
        <a routerLink="/city">Cities</a>
        <a *ngIf="isLogin" (click)="router.navigateByUrl('/?book=now')">Book Now</a>
        <a *ngIf="!isLogin" routerLink="/account/login">Book Now</a>
      </div>
    </div>
    <div class="buttons-div">
      <a>
        <button class="signup-btn mr-1" routerLink="/account/phone-verification" type="button">Register</button>
      </a>
      <a>
        <button class="login-btn" routerLink="/account/login" type="button">Login</button>
      </a>
    </div>
  </nav>

  <nav class="navbar d-flex justify-content-between align-items-center med-screen">
    <div class="d-flex align-items-center left-menu">
      <a class="navbar-brand">
        <img routerLink="/" src="/assets/images/Logo.svg" class="d-inline-block align-top"
          alt="Cab Scanner Logo">
      </a>
    </div>
    <div class="buttons-div">
      <a (click)="gs.openSidebar()">
        <em class="fas fa-bars"></em>
      </a>
    </div>
  </nav>
</header>

<!-- Dashboard Navbar/mobile screen -->
<header *ngIf="isLogin && hideTopbar == 'false'" class="sticky-top">
  <nav class="navbar d-flex justify-content-between flex-wrap lg-screen">
    <div>
      <a class="navbar-brand">
        <img routerLink="/" src="/assets/images/Logo.svg" class="d-inline-block align-top" alt="Cab Scanner Logo">
      </a>
    </div>
    <div class="menu-section-items">
      <!-- <a routerLink="/airport-geofencing" class="navbar-main-items no-display-small">
        Airport Geofencing
      </a> -->
      <a (click)="router.navigateByUrl('/?book=now')" class="navbar-main-items no-display-small">
        Book Now
      </a>
      <a routerLink="/rides" class="navbar-main-items no-display-small">
        My Rides
      </a>
      <a class="dropdown notification-dropdown navbar-main-items mr-2 d-none">
        <span class="" id="dropdownMenuNotify" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
          <em class="far fa-bell"></em>
        </span>
        <div class="dropdown-menu notification-dropdown-inner" aria-labelledby="dropdownMenuNotify">
          <em class="fas fa-caret-up"></em>
          <ul class="list-group dropdown-item">
            <li class="list-group-item">
              <img src="/assets/images/yellow-notify.png" alt="" height="40" class="pl-3">
              <a class="list-drop-menu">
                <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Veniam modi nobis commodi
                </p>
              </a>
            </li>
            <li class="list-group-item">
              <img src="/assets/images/yellow-notify.png" alt="" height="40" class="pl-3">
              <a class="list-drop-menu">
                <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Veniam modi nobis commodi
                </p>
              </a>
            </li>
            <li class="list-group-item">
              <img src="/assets/images/yellow-notify.png" alt="" height="40" class="pl-3">
              <a class="list-drop-menu">
                <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Veniam modi nobis commodi
                </p>
              </a>
            </li>
            <li class="list-group-item">
              <img src="/assets/images/yellow-notify.png" alt="" height="40" class="pl-3">
              <a class="list-drop-menu">
                <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Veniam modi nobis commodi
                </p>
              </a>
            </li>
          </ul>
        </div>
      </a>
      <a class="dropdown profile-dropdown navbar-main-items">
        <img [src]="user.imageUrl && user.imageUrl != 'string' ? user.imageUrl : '/assets/images/user.png'"
          class="rounded-circle" alt="" width="40" height="40">
        <span class="ml-2" id="dropdownMenuProfile" data-toggle="dropdown" aria-haspopup="true"
          aria-expanded="false"><span
            class="user-name">{{ this.user && this.user.firstName + (user.lastName && user.lastName != " " && user.lastName != "null" ? " " + user.lastName : "") }}
          </span>
          <em class="fas fa-chevron-down pl-1"></em>
        </span>
        <div class="dropdown-menu profile-dropdown-inner" aria-labelledby="dropdownMenuProfile">
          <a class="dropdown-item small-screen-items" (click)="router.navigateByUrl('/?book=now')">Book
            Now</a>
          <a class="dropdown-item small-screen-items" routerLink="/rides">My Rides</a>
          <a class="dropdown-item" routerLink="/account/profile">Profile</a>
          <a class="dropdown-item" (click)="logout()">Logout</a>
        </div>
      </a>
    </div>
  </nav>
  <nav class="navbar d-flex justify-content-between align-items-center med-screen">
    <div class="d-flex align-items-center left-menu">
      <a class="navbar-brand">
        <img routerLink="/" src="/assets/images/Logo.svg" class="d-inline-block align-top"
          alt="Cab Scanner Logo">
      </a>
    </div>
    <div class="buttons-div">
      <a (click)="gs.openSidebar()">
        <em class="fas fa-bars"></em>
      </a>
    </div>
  </nav>
</header>

<!-- <header class="d-none">
  <nav class="navbar d-flex justify-content-between flex-wrap">
    <div>
      <a class="navbar-brand">
        <img routerLink="/" src="/assets/images/Logo.svg" class="d-inline-block align-top"
          alt="Cab Scanner Logo">
      </a>
    </div>
    <div class="menu-section-items">
      <a href="" class="navbar-main-items no-display-small">
        Book Now
      </a>
      <a href="" class="navbar-main-items no-display-small">
        My Rides
      </a>
      <a href="" class="dropdown notification-dropdown navbar-main-items mr-2">
        <span class="" id="dropdownMenuNotify" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
          <em class="far fa-bell"></em>
        </span>
        <div class="dropdown-menu notification-dropdown-inner" aria-labelledby="dropdownMenuNotify">
          <em class="fas fa-caret-up"></em>
          <ul class="list-group dropdown-item">
            <li class="list-group-item">
              <img src="/assets/images/yellow-notify.png" alt="" height="40" class="pl-3">
              <a class="list-drop-menu">
                <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Veniam modi nobis commodi
                </p>
              </a>
            </li>
            <li class="list-group-item">
              <img src="/assets/images/yellow-notify.png" alt="" height="40" class="pl-3">
              <a class="list-drop-menu">
                <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Veniam modi nobis commodi
                </p>
              </a>
            </li>
            <li class="list-group-item">
              <img src="/assets/images/yellow-notify.png" alt="" height="40" class="pl-3">
              <a class="list-drop-menu">
                <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Veniam modi nobis commodi
                </p>
              </a>
            </li>
            <li class="list-group-item">
              <img src="/assets/images/yellow-notify.png" alt="" height="40" class="pl-3">
              <a class="list-drop-menu">
                <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Veniam modi nobis commodi
                </p>
              </a>
            </li>
          </ul>
        </div>
      </a>
      <a href="" class="dropdown profile-dropdown navbar-main-items">
        <img src="/assets/images/user.png" alt="" height="40">
        <span class="ml-2" id="dropdownMenuProfile" data-toggle="dropdown" aria-haspopup="true"
          aria-expanded="false">Sarmad
          <em class="fas fa-chevron-down pl-1"></em>
        </span>
        <div class="dropdown-menu profile-dropdown-inner" aria-labelledby="dropdownMenuProfile">
          <a class="dropdown-item small-screen-items" (click)="router.navigateByUrl('/?book=now')">Book
            Now</a>
          <a class="dropdown-item small-screen-items" routerLink="/rides">My Rides</a>
          <a class="dropdown-item" routerLink="/account/profile">Profile</a>
          <a class="dropdown-item" (click)="logout()">Logout</a>
        </div>
      </a>
    </div>
  </nav>
</header> -->
