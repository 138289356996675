import { CustomHttpMessagesComponent } from './components/custom-http-messages/custom-http-messages.component';
import { GeoFenceComponent } from './components/geo-fence/geo-fence.component';
import { CitiesComponent } from './components/cities/cities.component';
import { ContactUsComponent } from './components/contact-us/contact-us.component';
import { FaqsComponent } from './components/faqs/faqs.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from 'src/app/auth.guard';
import { PrivacyPolicyComponent } from './components/privacy-policy/privacy-policy.component';
import { TermsAndConditionsComponent } from './components/terms-and-conditions/terms-and-conditions.component';

const routes: Routes = [
  {
    path: '',
    loadChildren: () => import('./components/home/home.module').then(m => m.HomeModule)
  },
  {
    path: 'quotes',
    canActivate: [AuthGuard],
    loadChildren: () => import('./components/quotes/quotes.module').then(m => m.QuotesModule),
  },
  {
    path: 'account',
    loadChildren: () => import('./components/account/account.module').then(m => m.AccountModule),
  },
  {
    path: 'rides',
    loadChildren: () => import('./components/my-rides/my-rides.module').then(m => m.MyRidesModule),
  },
  {
    path: 'airport-geofencing',
    canActivate: [AuthGuard],
    loadChildren: () => import('./components/airport-geofencing/airport-geofencing.module').then(m => m.AirportGeofencingModule),
  },
  {
    path: 'invoice/:rideReqId',
    loadChildren: () => import('./components/invoice/invoice.module').then(m => m.InvoiceModule),
  },
  {
    path: 'faqs',
    component: FaqsComponent
  },
  {
    path: 'privacy-policy',
    component: PrivacyPolicyComponent
  },
  {
    path: 'terms-and-conditions',
    component: TermsAndConditionsComponent
  },
  {
    path: 'fleet',
    loadChildren: () => import('./components/fleet/fleet.module').then(m => m.FleetModule),
  },
  {
    path: 'contact-us',
    component: ContactUsComponent
  },
  {
    path: 'city',
    component: CitiesComponent
  },
  {
    path: 'geo-fence',
    component: GeoFenceComponent
  },
  {
    path: 'custom-messages',
    component: CustomHttpMessagesComponent
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
