import { Component } from '@angular/core';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { NgcCookieConsentService, NgcInitializeEvent, NgcNoCookieLawEvent, NgcStatusChangeEvent } from 'ngx-cookieconsent';
import { LocalStorageService } from 'ngx-localstorage';
import { Subscription } from 'rxjs';
import Constants from './classes/Constants';
import { AuthInterface } from './interfaces/AuthInterface';
import { AccountService } from './services/account.service';
import { EventMqttService } from './services/event.mqtt.service';
import { GlobalService } from './services/global.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'cabscanner';

  loadingCompleted = false;

  auth: AuthInterface;

  topicSubscriber = null;

  private popupOpenSubscription: Subscription;
  private popupCloseSubscription: Subscription;
  private initializeSubscription: Subscription;
  private statusChangeSubscription: Subscription;
  private revokeChoiceSubscription: Subscription;
  private noCookieLawSubscription: Subscription;

  mobileOnly = false

  constructor(private ccService: NgcCookieConsentService, private _topics: Constants, private activatedRoute: ActivatedRoute, private _mqttService: EventMqttService, public _global: GlobalService, public _accountS: AccountService, public ls: LocalStorageService, public router: Router) {
    this.activatedRoute.queryParams.subscribe(params => {
      if (params && params["mobileOnly"])
        this.mobileOnly = true;
    });

    router.events.subscribe(url => {
      // True means hide it, false means otherwise
      if (url instanceof NavigationEnd && url["url"] && (url["url"].includes("fleet/home") || url["url"].includes("fleet/driver-register")))
        this._global.setTopbarVisibility('true')
      else if (url instanceof NavigationEnd && url["url"] && !url["url"].includes("fleet/home") && !url["url"].includes("fleet/driver-register"))
        this._global.setTopbarVisibility('false')

      if (url instanceof NavigationEnd && url["url"] && url["url"] != "/")
        setTimeout(() => {
          window.scroll(0, 0)
        }, 100);
    })
  }

  async ngOnInit() {
    if (!this.ls.get("cookie-consent")) {
      this.ccService.fadeIn();
      setTimeout(() => {
        let ccAllow = document.getElementsByClassName("cc-allow");
        if (ccAllow.length) {
          ccAllow[0].setAttribute('id', 'ccAllowCustom')
          ccAllow[0].setAttribute("onclick", "cookieConsentAllow();")
        }
      }, 1000);
      if (this.ls.get("auth")) {
        this.ls.remove("auth");
        return this.router.navigateByUrl("/");
      }
    } else {
      this.ccService.fadeOut();
      if (document.getElementsByClassName("cc-window").length)
        (document.getElementsByClassName("cc-window")[0] as HTMLDivElement).style.display = "none"
    }

    // this.statusChangeSubscription =
    this.ccService.statusChange$.subscribe(
      (event: NgcStatusChangeEvent) => {
        // This information has to be stored in DB (Shafqat Bhai API change) so that everytime when same user comes back, system knows and do not asks again
        if (event.status !== "allow") {
          this.ls.set("cookie-consent", false);
          this.ls.remove("auth");
          alert("Please note that without accepting cookies consent, you may not be able to book any ride");
          // location.reload()
          return this.router.navigateByUrl("/");
        }
      });

    this._mqttService.rideAcceptEventSubscribed.subscribe(isSubsc => {
      if (isSubsc) {
        clearInterval(this.topicSubscriber)
      }
    })

    this.topicSubscriber = setInterval(() => {
      if (this.ls.get("auth") && this.ls.get("auth").id_token !== "" && this.ls.get("auth").id_token !== null) {
        if (this._mqttService.isConnected) {
          // Ride Accept Topic
          this._mqttService.subscribeTopic(this._topics.topicRideAccept.replace("+", this.ls.get("auth").user.id));
          this._mqttService.rideAcceptSubscribed(true)
        } else
          this._mqttService.connect();
      }
    }, 1000);

    if (this.ls.get("auth") && this.ls.get("auth").id_token !== "" && this.ls.get("auth").id_token !== null) {
      //Logged In
      this._global.saveFcmtoken();

      this._accountS.profile().subscribe(x => {
        this.auth = {
          user: x,
          id_token: this.ls.get("auth").id_token
        };
        this.ls.set('auth', this.auth);
        this._accountS.setIsLogin(true);
        this._global.setLoadingStatus(true)
      }, err => {
        this._accountS.setIsLogin(false);
        this._global.setLoadingStatus(true)
        this.router.navigateByUrl("/");
      })
    } else
      this._global.setLoadingStatus(true)
    this._global.loadingCompleted.subscribe(x => this.loadingCompleted = x);
  }

  ngOnDestroy() {
    // unsubscribe to cookieconsent observables to prevent memory leaks
    // this.popupOpenSubscription.unsubscribe();
    // this.popupCloseSubscription.unsubscribe();
    // this.initializeSubscription.unsubscribe();
    // this.statusChangeSubscription.unsubscribe();
    // this.revokeChoiceSubscription.unsubscribe();
    // this.noCookieLawSubscription.unsubscribe();
  }

}
