<div class="terms-conditions">
<div class="heading">
  <h4>Terms &
    Conditions</h4>
</div>
<div class="body">
    <p class="fw6">Last updated: May, 2021</p>
    <br>
    <p class="fw6">1. Contractual Relationship</p>
    <p>PLEASE READ THESE TERMS CAREFULLY BEFORE ACCESSING OR USING THE SERVICES.</p>

    <p>Your access and use of the Services constitutes your agreement to be bound by these Terms, which establishes a
      contractual relationship between you and CabScanner. If you do not agree to these Terms, you may not access or use
      the Services. These Terms expressly supersede prior agreements or arrangements with you. CabScanner may
      immediately
      terminate these Terms or any Services with respect to you, or generally cease offering or deny access to the
      Services or any portion thereof, at any time for any reason.</p>

    <p>Supplemental terms may apply to certain Services, such as policies for a particular event, activity or promotion,
      and such supplemental terms will be disclosed to you in connection with the applicable Services. Supplemental
      terms
      are in addition to, and shall be deemed a part of, the Terms for the purposes of the applicable Services.
      Supplemental terms shall prevail over these Terms in the event of a conflict with respect to the applicable
      Services.</p>

    <p>CabScanner may amend the Terms related to the Services from time to time. Amendments will be effective upon
      CabScanner’s posting of such updated Terms at this location or the amended policies or supplemental terms on the
      applicable Service. Your continued access or use of the Services after such posting constitutes your consent to be
      bound by the Terms, as amended.</p>

    <p>Our collection and use of personal information in connection with the Services is as provided in CabScanner’s
      Privacy Policy. CabScanner may provide to a claims processor or an insurer any necessary information (including
      your
      contact information) if there is a complaint, dispute or conflict, which may include an accident, involving you
      and
      a Third Party Provider (including a transportation network company driver) and such information or data is
      necessary
      to resolve the complaint, dispute or conflict.
    </p>

    <br>
    <p class="fw6">2. The Services</p>
    <p>
      The Services constitute a technology platform that enables users of CabScanner’s mobile applications or websites
      provided as part of the Services (each, an “Application”) to arrange and schedule transportation and/or logistics
      services with independent third party providers of such services, including independent third party transportation
      providers and independent third party logistics providers under agreement with CabScanner or certain of
      CabScanner’s affiliates (“Third Party Providers”). Unless otherwise agreed by CabScanner in a separate written
      agreement with you, the Services are made available solely for your personal, noncommercial use. YOU ACKNOWLEDGE
      THAT CABSCANNER DOES NOT PROVIDE TRANSPORTATION OR LOGISTICS SERVICES OR FUNCTION AS A TRANSPORTATION CARRIER AND
      THAT ALL SUCH TRANSPORTATION OR LOGISTICS SERVICES ARE PROVIDED BY INDEPENDENT THIRD PARTY CONTRACTORS WHO ARE NOT
      EMPLOYED BY CABSCANNER OR ANY OF ITS AFFILIATES.
    </p>

    <br>
    <p class="fw6">License.</p>
    <p>Subject to your compliance with these Terms, CabScanner grants you a limited, non-exclusive, non-sublicensable,
      revocable, non-transferrable license to: (i) access and use the Applications on your personal device solely in
      connection with your use of the Services; and (ii) access and use any content, information and related materials
      that may be made available through the Services, in each case solely for your personal, noncommercial use. Any
      rights not expressly granted herein are reserved by CabScanner and CabScanner’s licensors.
    </p>

    <br>
    <p class="fw6">Restrictions:</p>
    <p>You may not: (i) remove any copyright, trademark or other proprietary notices from any portion of the Services;
      (ii) reproduce, modify, prepare derivative works based upon, distribute, license, lease, sell, resell, transfer,
      publicly display, publicly perform, transmit, stream, broadcast or otherwise exploit the Services except as
      expressly permitted by CabScanner; (iii) decompile, reverse engineer or disassemble the Services except as may be
      permitted by applicable law; (iv) link to, mirror or frame any portion of the Services; (v) cause or launch any
      programs or scripts for the purpose of scraping, indexing, surveying, or otherwise data mining any portion of the
      Services or unduly burdening or hindering the operation and/or functionality of any aspect of the Services; or
      (vi) attempt to gain unauthorized access to or impair any aspect of the Services or its related systems or
      networks.
    </p>

    <br>
    <p class="fw6">Third Party Services and Content.</p>
    <p>The Services may be made available or accessed in connection with third party services and content (including
      advertising) that CabScanner does not control. You acknowledge that different terms of use and privacy policies
      may apply to your use of such third party services and content. CabScanner does not endorse such third party
      services and content and in no event shall CabScanner be responsible or liable for any products or services of
      such third party providers. Additionally, Apple Inc., Google, Inc., and/or their applicable international
      subsidiaries and affiliates will be third-party beneficiaries to this contract if you access the Services using
      Applications developed for Apple iOS, Android, mobile devices, respectively. These third party beneficiaries are
      not parties to this contract and are not responsible for the provision or support of the Services in any manner.
      Your access to the Services using these devices is subject to terms set forth in the applicable third party
      beneficiary’s terms of service.</p>

    <p class="fw6">Ownership.</p>
    <p>The Services and all rights therein are and shall remain CabScanner’s property or the property of CabScanner’s
      licensors. Neither these Terms nor your use of the Services convey or grant to you any rights: (i) in or related
      to the Services except for the limited license granted above; or (ii) to use or reference in any manner
      CabScanner’s company names, logos, product and service names, trademarks or services marks or those of
      CabScanner’s licensors.</p>

    <br>
    <p class="fw6">3. Your Use of the Services</p>
    <p class="fw6">User Accounts.</p>
    <p>
      In order to use most aspects of the Services, you must register for and maintain an active personal user Services
      account (“Account”). You must be at least 18 years of age, or the age of legal majority in your jurisdiction (if
      different than 18), to obtain an Account. Account registration requires you to submit to CabScanner certain
      personal information, such as your name, address, mobile phone number, as well as at least one valid payment
      method (either a credit card or accepted payment partner). You agree to maintain accurate, complete, and
      up-to-date information in your Account. Your failure to maintain accurate, complete, and up-to-date Account
      information, including having an invalid or expired payment method on file, may result in your inability to access
      and use the Services or CabScanner’s termination of these Terms with you. You are responsible for all activity
      that occurs under your Account, and you agree to maintain the security and secrecy of your Account username and
      password at all times. Unless otherwise permitted by CabScanner in writing, you may only possess one Account.
    </p>

    <p class="fw6">User Requirements and Conduct.</p>
    <p>
      The Service is not available for use by persons under the age of 18. You may not authorize third parties to use
      your
      Account, and you may not allow persons under the age of 18 to receive transportation or logistics services from
      Third Party Providers unless they are accompanied by you. You may not assign or otherwise transfer your Account to
      any other person or entity. You agree to comply with all applicable laws when using the Services, and you may only
      use the Services for lawful purposes (e.g., no transport of unlawful or hazardous materials). You will not, in
      your
      use of the Services, cause nuisance, annoyance, inconvenience, or property damage, whether to the Third Party
      Provider or any other party. In certain instances you may be asked to provide proof of identity to access or use
      the
      Services, and you agree that you may be denied access to or use of the Services if you refuse to provide proof of
      identity.
    </p>

    <p class="fw6">Text Messaging.</p>
    <p>By creating an Account, you agree that the Services may send you text (SMS) messages as part of the normal
      business
      operation of your use of the Services.
      Promotional Codes.</p>
    <p>CabScanner may, in CabScanner’s sole discretion, create promotional codes that may be redeemed for Account
      credit,
      or other features or benefits related to the Services and/or a Third Party Provider’s services, subject to any
      additional terms that CabScanner establishes on a per promotional code basis (“Promo Codes”). You agree that Promo
      Codes: (i) must be used for the intended audience and purpose, and in a lawful manner; (ii) may not be duplicated,
      sold or transferred in any manner, or made available to the general public (whether posted to a public form or
      otherwise), unless expressly permitted by CabScanner; (iii) may be disabled by CabScanner at any time for any
      reason
      without liability to CabScanner; (iv) may only be used pursuant to the specific terms that CabScanner establishes
      for such Promo Code; (v) are not valid for cash; and (vi) may expire prior to your use. CabScanner reserves the
      right to withhold or deduct credits or other features or benefits obtained through the use of Promo Codes by you
      or
      any other user in the event that CabScanner determines or believes that the use or redemption of the Promo Code
      was
      in error, fraudulent, illegal, or in violation of the applicable Promo Code terms or these Terms.
    </p>

    <p class="fw6">User Provided Content.</p>
    <p>CabScanner may, in CabScanner’s sole discretion, permit you from time to time to submit, upload, publish or
      otherwise make available to CabScanner through the Services textual, audio, and/or visual content and information,
      including commentary and feedback related to the Services, initiation of support requests, and submission of
      entries for competitions and promotions (“User Content”). Any User Content provided by you remains your property.
      However,
      by providing User Content to CabScanner, you grant CabScanner a worldwide, perpetual, irrevocable, transferrable,
      royalty-free license, with the right to sublicense, to use, copy, modify, create derivative works of, distribute,
      publicly display, publicly perform, and otherwise exploit in any manner such User Content in all formats and
      distribution channels now known or hereafter devised (including in connection with the Services and CabScanner’s
      business and on third-party sites and services), without further notice to or consent from you, and without the
      requirement of payment to you or any other person or entity.</p>
    <p>
      You represent and warrant that: (i) you either are the sole and exclusive owner of all User Content or you have
      all
      rights, licenses, consents and releases necessary to grant CabScanner the license to the User Content as set forth
      above; and (ii) neither the User Content nor your submission, uploading, publishing or otherwise making available
      of
      such User Content nor CabScanner’s use of the User Content as permitted herein will infringe, misappropriate or
      violate a third party’s intellectual property or proprietary rights, or rights of publicity or privacy, or result
      in
      the violation of any applicable law or regulation.
    </p>

    <p>You agree to not provide User Content that is defamatory, libelous, hateful, violent, obscene, pornographic,
      unlawful, or otherwise offensive, as determined by CabScanner in its sole discretion, whether or not such material
      may be protected by law. CabScanner may, but shall not be obligated to, review, monitor, or remove User Content,
      at
      CabScanner’s sole discretion and at any time and for any reason, without notice to you.</p>

    <p>Network Access and Devices.
      You are responsible for obtaining the data network access necessary to use the Services. Your mobile network’s
      data
      and messaging rates and fees may apply if you access or use the Services from a wireless-enabled device and you
      shall be responsible for such rates and fees. You are responsible for acquiring and updating compatible hardware
      or
      devices necessary to access and use the Services and Applications and any updates thereto. CabScanner does not
      guarantee that the Services, or any portion thereof, will function on any particular hardware or devices. In
      addition, the Services may be subject to malfunctions and delays inherent in the use of the Internet and
      electronic
      communications.</p>

    <br>
    <p class="fw6">4. Payment</p>
    <p>You understand that use of the Services may result in charges to you for the services or goods you receive from a
      Third Party Provider (“Charges ”). After you have received services or goods obtained through your use of the
      Service, CabScanner will facilitate your payment of the applicable Charges on behalf of the Third Party Provider
      as such Third Party Provider’s limited payment collection agent. Payment of the Charges in such manner shall be
      considered the same as payment made directly by you to the Third Party Provider. Charges will be inclusive of
      applicable taxes where required by law. Charges paid by you are final and non-refundable, unless otherwise
      determined by CabScanner. You retain the right to request lower Charges from a Third Party Provider for services
      or goods received by you from such Third Party Provider at the time you receive such services or goods. CabScanner
      will respond accordingly to any request from a Third Party Provider to modify the Charges for a particular service
      or good.
    </p>
    <p>
      All Charges are due immediately and payment will be facilitated by CabScanner using the preferred payment method
      designated in your Account, after which CabScanner will send you a receipt. If your primary Account payment method
      is determined to be expired, invalid or otherwise not able to be charged, you agree that CabScanner may, as the
      Third Party Provider’s limited payment collection agent, use a secondary payment method in your Account, if
      available.
    </p>
    <p>
      As between you and CabScanner, CabScanner reserves the right to establish, remove and/or revise Charges for any or
      all services or goods obtained through the use of the Services at any time in CabScanner’s sole discretion.
      Further, you acknowledge and agree that Charges applicable in certain geographical areas may increase
      substantially during times of high demand. CabScanner will use reasonable efforts to inform you of Charges that
      may apply, provided that you will be responsible for Charges incurred under your Account regardless of your
      awareness of such Charges or the amounts thereof. CabScanner may from time to time provide certain users with
      promotional offers and discounts that may result in different amounts charged for the same or similar services or
      goods obtained through the use of the Services, and you agree that such promotional offers and discounts, unless
      also made available to you, shall have no bearing on your use of the Services or the Charges applied to you. You
      may elect to cancel your request for services or goods from a Third Party Provider at any time prior to such Third
      Party Provider’s arrival, in which case you may be charged a cancellation fee.
    </p>
    <p>
      This payment structure is intended to fully compensate the Third Party Provider for the services or goods
      provided. Except with respect to taxicab transportation services requested through the Application, CabScanner
      does not designate any portion of your payment as a tip or gratuity to the Third Party Provider. Any
      representation by CabScanner (on CabScanner’s website, in the Application, or in CabScanner’s marketing materials)
      to the effect that tipping is “voluntary,” “not required,” and/or “included” in the payments you make for services
      or goods provided is not intended to suggest that CabScanner provides any additional amounts, beyond those
      described above, to the Third Party Provider. You understand and agree that, while you are free to provide
      additional payment as a gratuity to any Third Party Provider who provides you with services or goods obtained
      through the Service, you are under no obligation to do so. Gratuities are voluntary. After you have received
      services or goods obtained through the Service, you will have the opportunity to rate your experience and leave
      additional feedback about your Third Party Provider.
    </p>
    <p class="fw6">Repair or Cleaning Fees.</p>
    <p>
      You shall be responsible for the cost of repair for damage to, or necessary cleaning of, Third Party Provider
      vehicles and property resulting from use of the Services under your Account in excess of normal “wear and tear”
      damages and necessary cleaning (“Repair or Cleaning”). In the event that a Third Party Provider reports the need
      for Repair or Cleaning, and such Repair or Cleaning request is verified by CabScanner in CabScanner’s reasonable
      discretion, CabScanner reserves the right to facilitate payment for the reasonable cost of such Repair or Cleaning
      on behalf of the Third Party Provider using your payment method designated in your Account. Such amounts will be
      transferred by CabScanner to the applicable Third Party Provider and are non-refundable.
    </p>

    <p class="fw6">5. Disclaimers; Limitation of Liability; Indemnity.</p>
    DISCLAIMER.
    <p>THE SERVICES ARE PROVIDED “AS IS” AND “AS AVAILABLE.” CABSCANNER DISCLAIMS ALL REPRESENTATIONS AND WARRANTIES,
      EXPRESS, IMPLIED OR STATUTORY, NOT EXPRESSLY SET OUT IN THESE TERMS, INCLUDING THE IMPLIED WARRANTIES OF
      MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE AND NON-INFRINGEMENT. IN ADDITION, CABSCANNER MAKES NO
      REPRESENTATION, WARRANTY, OR GUARANTEE REGARDING THE RELIABILITY, TIMELINESS, QUALITY, SUITABILITY OR AVAILABILITY
      OF THE SERVICES OR ANY SERVICES OR GOODS REQUESTED THROUGH THE USE OF THE SERVICES, OR THAT THE SERVICES WILL BE
      UNINTERRUPTED OR ERROR-FREE. CABSCANNER DOES NOT GUARANTEE THE QUALITY, SUITABILITY, SAFETY OR ABILITY OF THIRD
      PARTY PROVIDERS. YOU AGREE THAT THE ENTIRE RISK ARISING OUT OF YOUR USE OF THE SERVICES, AND ANY SERVICE OR GOOD
      REQUESTED IN CONNECTION THEREWITH, REMAINS SOLELY WITH YOU, TO THE MAXIMUM EXTENT PERMITTED UNDER APPLICABLE LAW.
    </p>

    <p>LIMITATION OF LIABILITY.</p>
    <p>CABSCANNER SHALL NOT BE LIABLE FOR INDIRECT, INCIDENTAL, SPECIAL, EXEMPLARY, PUNITIVE OR CONSEQUENTIAL DAMAGES,
      INCLUDING LOST PROFITS, LOST DATA, PERSONAL INJURY OR PROPERTY DAMAGE RELATED TO, IN CONNECTION WITH, OR OTHERWISE
      RESULTING FROM ANY USE OF THE SERVICES, EVEN IF CABSCANNER HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES.
      CABSCANNER SHALL NOT BE LIABLE FOR ANY DAMAGES, LIABILITY OR LOSSES ARISING OUT OF: (i) YOUR USE OF OR RELIANCE ON
      THE SERVICES OR YOUR INABILITY TO ACCESS OR USE THE SERVICES; OR (ii) ANY TRANSACTION OR RELATIONSHIP BETWEEN YOU
      AND ANY THIRD PARTY PROVIDER, EVEN IF CABSCANNER HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. CABSCANNER
      SHALL NOT BE LIABLE FOR DELAY OR FAILURE IN PERFORMANCE RESULTING FROM CAUSES BEYOND CABSCANNER’S REASONABLE
      CONTROL. YOU ACKNOWLEDGE THAT THIRD PARTY TRANSPORTATION PROVIDERS PROVIDING TRANSPORTATION SERVICES REQUESTED
      THROUGH SOME REQUEST BRANDS MAY OFFER RIDESHARING OR PEER-TO-PEER TRANSPORTATION SERVICES AND MAY NOT BE
      PROFESSIONALLY LICENSED OR PERMITTED. IN NO EVENT SHALL CABSCANNER’S TOTAL LIABILITY TO YOU IN CONNECTION WITH THE
      SERVICES FOR ALL DAMAGES, LOSSES.</p>

    <p>CABSCANNER’S SERVICES MAY BE USED BY YOU TO REQUEST AND SCHEDULE TRANSPORTATION, GOODS OR LOGISTICS SERVICES WITH
      THIRD PARTY PROVIDERS, BUT YOU AGREE THAT CABSCANNER HAS NO RESPONSIBILITY OR LIABILITY TO YOU RELATED TO ANY
      TRANSPORTATION, GOODS OR LOGISTICS SERVICES PROVIDED TO YOU BY THIRD PARTY PROVIDERS OTHER THAN AS EXPRESSLY SET
      FORTH IN THESE TERMS.</p>

    <p>THE LIMITATIONS AND DISCLAIMER IN THIS SECTION 5 DO NOT PURPORT TO LIMIT LIABILITY OR ALTER YOUR RIGHTS AS A
      CONSUMER THAT CANNOT BE EXCLUDED UNDER APPLICABLE LAW.
    </p>

    <p class="fw6">Indemnity.</p>
    <p>You agree to indemnify and hold CabScanner and its officers, directors, employees and agents harmless from any
      and all claims, demands, losses, liabilities, and expenses (including attorneys’ fees) arising out of or in
      connection with: (i) your use of the Services or services or goods obtained through your use of the Services; (ii)
      your breach or violation of any of these Terms; (iii) CabScanner’s use of your User Content; or (iv) your
      violation of the rights of any third party, including Third Party Providers.</p>

    <br>
    <p class="fw6">6. Other Provisions</p>

    General.
    <p>You may not assign or transfer these Terms in whole or in part without CabScanner’s prior written approval. You
      give your approval to CabScanner for it to assign or transfer these Terms in whole or in part, including to: (i) a
      subsidiary or affiliate; (ii) an acquirer of CabScanner’s equity, business or assets; or (iii) a successor by
      merger. No joint venture, partnership, employment or agency relationship exists between you, CabScanner or any
      Third Party Provider as a result of the contract between you and CabScanner or use of the Services.</p>

    <p>If any provision of these Terms is held to be illegal, invalid or unenforceable, in whole or in part, under any
      law, such provision or part thereof shall to that extent be deemed not to form part of these Terms but the
      legality, validity and enforceability of the other provisions in these Terms shall not be affected. In that event,
      the parties shall replace the illegal, invalid or unenforceable provision or part thereof with a provision or part
      thereof that is legal, valid and enforceable and that has, to the greatest extent possible, a similar effect as
      the illegal, invalid or unenforceable provision or part thereof, given the contents and purpose of these Terms.
      These Terms constitute the entire agreement and understanding of the parties with respect to its subject matter
      and replaces and supersedes all prior or contemporaneous agreements or undertakings regarding such subject matter.
      In these Terms, the words “including” and “include” mean “including, but not limited to.”</p>
</div>
