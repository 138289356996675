import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FcmMessagingService } from './services/fcm-messaging.service';
import { environment } from './../environments/environment';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HttpClientModule } from '@angular/common/http';
import { NavbarComponent } from './components/layout/navbar/navbar.component';
import { FooterComponent } from './components/layout/footer/footer.component';
import { SidebarComponent } from './components/layout/sidebar/sidebar.component';

import { GlobalService } from './services/global.service';
import { AccountService } from './services/account.service';
import { ApisService } from './services/apis.service';
import { EventMqttService } from './services/event.mqtt.service';
import Constants from './classes/Constants';
import { NgxLocalStorageModule } from 'ngx-localstorage';
import { NgHttpLoaderModule } from 'ng-http-loader';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {
  NgcCookieConsentModule,
  NgcCookieConsentConfig,
} from 'ngx-cookieconsent';
import { PrivacyPolicyComponent } from './components/privacy-policy/privacy-policy.component';
import { TermsAndConditionsComponent } from './components/terms-and-conditions/terms-and-conditions.component';
import { FaqsComponent } from './components/faqs/faqs.component';
import { ContactUsComponent } from './components/contact-us/contact-us.component';
import { CitiesComponent } from './components/cities/cities.component';
import { AngularFireMessagingModule } from '@angular/fire/messaging';
import { AngularFireModule } from '@angular/fire';
import { GeoFenceComponent } from './components/geo-fence/geo-fence.component';
import { CustomHttpMessagesComponent } from './components/custom-http-messages/custom-http-messages.component';
// import { DragulaModule } from 'ng2-dragula';

const cookieConfig: NgcCookieConsentConfig = {
  cookie: {
    // domain: "http://stgportal.cabscannerhq.com"
    domain: 'http://stgportal.cabscannerhq.com',
  },
  palette: {
    popup: {
      background: '#000',
    },
    button: {
      background: '#f1d600',
    },
  },
  theme: 'edgeless',
  type: 'opt-out',
};

@NgModule({
  declarations: [
    AppComponent,
    NavbarComponent,
    FooterComponent,
    SidebarComponent,
    PrivacyPolicyComponent,
    TermsAndConditionsComponent,
    FaqsComponent,
    ContactUsComponent,
    CitiesComponent,
    GeoFenceComponent,
    CustomHttpMessagesComponent,
  ],
  imports: [
    BrowserModule,
    NgxLocalStorageModule.forRoot(),
    HttpClientModule,
    NgHttpLoaderModule.forRoot(),
    FormsModule,
    ReactiveFormsModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    AngularFireMessagingModule,
    AngularFireModule.initializeApp(environment.firebaseConfig),
    NgcCookieConsentModule.forRoot(cookieConfig),
    // DragulaModule.forRoot(),
  ],
  providers: [
    GlobalService,
    AccountService,
    ApisService,
    Constants,
    EventMqttService,
    FcmMessagingService,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
