<div class="terms-conditions">
  <div class="heading">
    <h4>FAQs</h4>
  </div>
  <div class="body">
    <strong>- How to use this app?</strong>
    <p>In order to use this app, you will need to register with CabScanner. To register, you just need your active phone
      number, email address and an active internet connection - then follow the below steps:</p>
    <ol>
      <li>On the home screen, click on Register as show in below image.
        <br>
        <img src="../../../assets/images/faq1.png" class="my-4" width="" height="80%" alt="">
      </li>
      <li>Now select your country, e.g. UK, and enter your valid mobile phone number. Then click on “Send Code”
        button as shown below :
        <br>
        <img src="../../../assets/images/faqs2.png" class="my-4" width="" height="80%" alt="">
      </li>
      <li>A 4-digit code will be sent to the entered phone number. Once received, please Enter this code in screen and
        click on “Confirm”.
        <img src="../../../assets/images/faqs3.png" class="my-4" width="" height="80%" alt="">
      </li>
      <li>You will then be requested to provide some personal details as shown below screen.
        <br>
        <img src="../../../assets/images/faqs4.png" class="my-4" width="" height="80%" alt="">
        <br>
        <strong>Note : if you have been referred by any one (friends or family) then enter your Referral code in field
          as shown in below image. (Referral Code field can be found by scrolling down the screen)</strong>
        <br>
        <img src="../../../assets/images/faqs5.png" class="my-4" width="" height="80%" alt="">
      </li>
      <li>Please click on check the box next to “I accept the terms and conditions”.</li>
      <li>Click on “Register”.</li>
      <li>To complete the process of Registration, you need to activate your account. For this you will receive an email
        on the registered email address - just click on the link provided in the email to complete the registration
        process.
        <br><br>
        <strong>Note: in case you try to login without activation you will received an alert as below :</strong>
        <br>
        <img src="../../../assets/images/faqs6.png" class="my-4" width="" height="80%" alt="">
        <br>
        After activation of your account, you are ready to book your first ride.
      </li>
    </ol>
    <strong>- How to use Referral?</strong>
    <p>You can earn and share rewards while referring your family and friends. To use this feature, open up the
      CabScanner app and follow below steps:</p>
    <ol>
      <li>Click on the menu icon (top left corner) to open up the Side Menu </li>
      <li>Click on Invite Friend
        <br>
        <img src="../../../assets/images/faqs7.png" class="my-4" width="" height="80%" alt="">
      </li>
      <li>On this screen, you can share the Referral Code by using two methods
        <ol type="a">
          <li>Copy the Referral Code by click the button as shown in below picture and share with your family & friend
            via email, whatsapp or text message.
            <br>
            <img src="../../../assets/images/faqs8.png" class="my-4" width="" height="80%" alt="">
          </li>
          <li>Click on the “Share Now” button and share it as shown in below picture<br>
            <img src="../../../assets/images/faqs9.png" class="my-4" width="" height="80%" alt="">
          </li>
        </ol>
        <strong>Note: In order to earn the referral reward, your Friend or Family member has to complete at least
          one ride with CabScanner!</strong>
      </li>
    </ol>
    <strong>- How to Top-up your wallet?</strong>
    <p>To Top up your wallet before doing ride, follow below steps:</p>
    <ol>
      <li>Click on the menu icon (top left corner) to open up the Side Menu
      </li>
      <li>Click on Wallet
        <br>
        <img src="../../../assets/images/faqs10.png" class="my-4" width="" height="80%" alt="">
      </li>
      <li>On the Wallet screen, it will show your current balance. In order to add credit, Click on “Top-up”
        <br>
        <img src="../../../assets/images/faqs11.png" class="my-4" width="" height="80%" alt="">
      </li>
      <li>There are various bundles available which offer more value or you can choose to top-up with a specific amount.
        See below picture :
        <br>
        <img src="../../../assets/images/faqs12.png" class="my-4" width="" height="80%" alt="">
      </li>
      <li>Select or enter the amount you want to Top-up and click “Confirm”. </li>
      <li>System will ask you to add a Credit/Debit Card. Enter valid card information, select the card and click
        “Pay”.</li>
      <strong>Note : you can use GooglePay and ApplePay as well (it will be available if you are using respective smart
        phone). </strong>
      <img src="../../../assets/images/faqs13.png" class="my-4" width="" height="80%" alt="">
    </ol>

    <strong>- How to pay for your ride?</strong>
    <p><strong class="primary-color">CabScanner</strong> app offers multiple methods to make payments. These include
      Cash, Wallet, Credit Card, GooglePay &
      ApplePay. These payment methods are dependent on Taxi Service provider’s consent, which they offer to use while
      booking a ride with them.
      <br>
      Whenever you book a ride, you will be asked for the Payment method to use - see below snapshot:
      <br>
      <img src="../../../assets/images/faqs14.png" class="my-4" width="" height="80%" alt="">
      <br>
      Payment will be made as per your selection.
      <br>
      <strong>Case 1:</strong>
      <br>
      In case you want to make payment from Wallet but do not have sufficient balance, you will be requested top-up your
      wallet before confirming the ride. Make sure you have minimum balance as per estimated fare to book a ride.
      <br><br><strong>Case 2:</strong>
      <br>
      In case you don’t want to top-up the wallet, you can select the Cash Option. At the end of the ride, you have to
      make the payment in Cash to the driver.
      <br><br><strong>Case 3:</strong>
      <br>
      In case you want to make payment through credit card, you will be requested to select the Credit Card (in case you
      have added multiple cards) or you can add a new card. Once added/selected, the payment(s) will be made by
      credit/debit card, until you choose a different method.
      <br>
      <br>
      <strong style="color:#000; font-style: italic">Note: All or some of the above payment options are available at the
        consent of Taxi Service Provider.</strong>
    </p>
  </div>
</div>