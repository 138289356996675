import { ApisService } from './../../services/apis.service';
import { GlobalService } from './../../services/global.service';
import { Component, OnInit } from '@angular/core';
import Constants from 'src/app/classes/Constants';

@Component({
  selector: 'app-custom-http-messages',
  templateUrl: './custom-http-messages.component.html',
  styleUrls: ['./custom-http-messages.component.scss']
})
export class CustomHttpMessagesComponent implements OnInit {

  sendingRequest = false;
  btnTxt = "Save";
  httpStatusEnum = [
    {
      id: 'DEACTIVATED_USER',
      value: 'Deactivated User'
    },
    {
      id: 'INCORRECT_EMAIL',
      value: 'Incorrect Email'
    },
    {
      id: 'INCORRECT_PASSWORD',
      value: 'Incorrect Password'
    }
  ];
  customMsg = {
    httpStatus: 1,
    responseMessage: null,
    responseTitle: null
  };

  httpMsgs = [];

  constructor(public _gs: GlobalService, private apiS: ApisService, public _const: Constants) { }

  ngOnInit(): void {
    this.getAllMessages();
  }

  getAllMessages() {
    this.apiS.getCustomHttpMessages().subscribe(x => {
      this.httpMsgs = x;
    })
  }

  getCustomHttpMsg() {
    const msgFnd = this.httpMsgs.find(x => x.httpStatus == this.customMsg.httpStatus);
    if (msgFnd)
      this.customMsg = JSON.parse(JSON.stringify(msgFnd));
    else
      this.customMsg = {
        httpStatus: this.customMsg.httpStatus,
        responseMessage: null,
        responseTitle: null
      }
  }

  saveMsg() {
    if (this.customMsg.httpStatus == 1 || !this.customMsg.responseMessage || !this.customMsg.responseTitle)
      return this._gs.showNotification("Missing Information", "Please provide required information", 300);

    let op = "add";
    if (this.httpMsgs.find(x => x.httpStatus == this.customMsg.httpStatus))
      op = "update";

    this.apiS.saveCustomHttpMessage(this.customMsg, op).subscribe(x => {
      this._gs.showNotification("Message Saved", "HTTP Message has been saved successfully");
      this.getAllMessages();
    }, error => {
      console.log(error);
      this._gs.showNotification("Failed", "Unable to save message. Please try again later", 400, 5000);
    });
  }

}
