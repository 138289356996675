import { GlobalService } from './../../services/global.service';
import { Router } from '@angular/router';
import { ApisService } from './../../services/apis.service';
import { Component, OnInit } from '@angular/core';
import Constants from 'src/app/classes/Constants';

@Component({
  selector: 'app-contact-us',
  templateUrl: './contact-us.component.html',
  styleUrls: ['./contact-us.component.scss']
})
export class ContactUsComponent implements OnInit {

  constructor(private _api: ApisService, public _gs: GlobalService, public _const: Constants, private _router: Router) { }

  ngOnInit(): void {
    (document.getElementById("name") as HTMLInputElement).focus();
  }

  sendingReq = false;

  contactUs() {
    if (this._gs.checkInvalids())
      return this._gs.showNotification("Invalid Information", "Please provide valid information", 300);
    this.sendingReq = true;
    this._api.contactUs({
      name: (document.getElementById("name") as HTMLInputElement).value,
      email: (document.getElementById("email") as HTMLInputElement).value,
      message: (document.getElementById("message") as HTMLInputElement).value
    }).subscribe(x => {
      this.sendingReq = false;
      this._gs.showNotification("Message sent", "You'll be contacted soon!");
      (document.getElementById("name") as HTMLInputElement).value = "";
      (document.getElementById("email") as HTMLInputElement).value = "";
      (document.getElementById("message") as HTMLInputElement).value = "";
      // return this._router.navigateByUrl("/")
    })
  }

}
