import { GlobalService } from './../../../services/global.service';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { LocalStorageService } from 'ngx-localstorage';
import { UserInterface } from 'src/app/interfaces/UserInterface';
import { AccountService } from 'src/app/services/account.service';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit {

  isLogin = false;
  user: UserInterface = null;
  hideTopbar = "false";

  constructor(public _accountS: AccountService, private _ls: LocalStorageService, public router: Router, public gs: GlobalService) { }

  ngOnInit(): void {
    this._accountS.isLogin.subscribe(x => {
      this.user = this._ls.get("auth") ? this._ls.get("auth").user : null;
      this.isLogin = x
    });

    this.gs.hideTopbar.subscribe(x => this.hideTopbar = x.toString());
  }

  logout() {
    this.gs.closeSidebar();
    this.gs.logout();
  }

}
