import { FcmMessagingService } from './fcm-messaging.service';
import { Router } from '@angular/router';
import { AccountService } from './account.service';
import { EventMqttService } from './event.mqtt.service';
import { Injectable, Optional } from '@angular/core';
import { LocalStorageService } from 'ngx-localstorage';
import { BehaviorSubject, Subject, throwError } from 'rxjs';
import { UserInterface } from '../interfaces/UserInterface';
import { ErrorInterface } from '../interfaces/ErrorInterface';
import Constants from '../classes/Constants';

@Injectable({
  providedIn: 'root',
})
export class GlobalService {
  private loadingCompletedBroadcast = new BehaviorSubject(false);
  loadingCompleted = this.loadingCompletedBroadcast.asObservable();

  private emailValidatorPattern =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  public postCodeRegex: RegExp =
    /^([Gg][Ii][Rr] 0[Aa]{2})|((([A-Za-z][0-9]{1,2})|(([A-Za-z][A-Ha-hJ-Yj-y][0-9]{1,2})|(([A-Za-z][0-9][A-Za-z])|([A-Za-z][A-Ha-hJ-Yj-y][0-9][A-Za-z]?))))\s?[0-9][A-Za-z]{2})$/;
  public alphaOnlyRegex: string = '^[A-Za-z -]+$';
  private alphabetic = /^[a-zA-Z\s]*$/;
  private numeric = /^[0-9]*$/;
  private float = /^[0-9.]*$/;
  private numericAndSpace = /^[0-9\s]*$/;
  public alphaNumeric: string = '^[a-zA-Z0-9]*$';
  private apiErrorBroadcast = new BehaviorSubject({});
  apiError = this.apiErrorBroadcast.asObservable();

  hideTopbar = new Subject();

  constructor(
    private fcmMessagingService: FcmMessagingService,
    @Optional() private localStorage: LocalStorageService,
    private router: Router,
    private eventMqtt: EventMqttService,
    private _accountS: AccountService,
    private _topics: Constants
  ) {}

  public getEmailPattern() {
    return this.emailValidatorPattern;
  }

  public isValidEmail(email) {
    return this.getEmailPattern().test(email);
  }

  setTopbarVisibility(visibility) {
    this.hideTopbar.next(visibility);
  }

  setLoadingStatus(status) {
    this.loadingCompletedBroadcast.next(status);
  }

  setApiError(error: ErrorInterface) {
    this.apiErrorBroadcast.next(error);
  }

  getUser(): UserInterface {
    return this.localStorage.get('auth').user;
  }

  getToken(): UserInterface {
    return this.localStorage.get('auth').token;
  }

  checkInvalids(parentDivId = null) {
    if (parentDivId) {
      return document
        .getElementById(parentDivId)
        .getElementsByClassName('warning-input').length;
    }
    return document.getElementsByClassName('warning-input').length;
  }

  removeWarnings() {
    let existingWarning = document.getElementsByTagName('small');
    for (let i = 0; i < existingWarning.length; i++) {
      if (existingWarning[i].classList.contains('warning-input'))
        existingWarning[i].remove();
    }
  }

  similarity(s1, s2) {
    var longer = s1;
    var shorter = s2;
    if (s1.length < s2.length) {
      longer = s2;
      shorter = s1;
    }
    var longerLength = longer.length;
    if (longerLength == 0) {
      return 1.0;
    }
    return (
      (longerLength - this.editDistance(longer, shorter)) /
      parseFloat(longerLength)
    );
  }

  openSidebar() {
    (document.getElementById('sidebar-menu') as HTMLElement).classList.add(
      'show-sidebar'
    );
    // (document.getElementsByTagName("body")[0] as HTMLBodyElement).style.overflow = "hidden";
  }

  closeSidebar() {
    (document.getElementById('sidebar-menu') as HTMLElement).classList.remove(
      'show-sidebar'
    );
    // (document.getElementsByTagName("body")[0] as HTMLBodyElement).style.overflow = "";
  }

  editDistance(s1, s2) {
    s1 = s1.toLowerCase();
    s2 = s2.toLowerCase();

    var costs = new Array();
    for (var i = 0; i <= s1.length; i++) {
      var lastValue = i;
      for (var j = 0; j <= s2.length; j++) {
        if (i == 0) costs[j] = j;
        else {
          if (j > 0) {
            var newValue = costs[j - 1];
            if (s1.charAt(i - 1) != s2.charAt(j - 1))
              newValue = Math.min(Math.min(newValue, lastValue), costs[j]) + 1;
            costs[j - 1] = lastValue;
            lastValue = newValue;
          }
        }
      }
      if (i > 0) costs[s2.length] = lastValue;
    }
    return costs[s2.length];
  }

  inputValidations(
    event,
    rules = {
      required: false,
      max: 0,
      maxVal: 0,
      min: 0,
      minVal: 0,
      email: false,
      pattern: null,
      restrict: true,
    }
  ) {
    // Restrict means to remove the last character
    let existingWarning = event.target.parentNode.getElementsByTagName('small');
    for (let i = 0; i < existingWarning.length; i++) {
      if (existingWarning[i].classList.contains('warning-input'))
        existingWarning[i].remove();
    }
    if (rules.required && event.target.value == '') {
      return this.setWarningMessage(
        event,
        'Please provide required information (*)'
      );
    }
    if (rules.pattern == 'alpha') {
      if (!this.alphabetic.test(event.target.value)) {
        if (rules.restrict)
          event.target.value = event.target.value.slice(0, -1);
        else
          return this.setWarningMessage(
            event,
            `Please provide valid information`
          );
      }
    }
    if (rules.pattern == 'num' || rules.pattern == 'float') {
      if (
        rules.pattern == 'num' &&
        !this.numeric.test(event.target.value) &&
        parseInt(event.target.value) < 0
      ) {
        if (rules.restrict)
          event.target.value = event.target.value.slice(0, -1);
        else
          return this.setWarningMessage(
            event,
            `Please provide valid information`
          );
      } else if (
        rules.pattern == 'float' &&
        !this.float.test(event.target.value)
      ) {
        if (rules.restrict)
          event.target.value = event.target.value.slice(0, -1);
        else
          return this.setWarningMessage(
            event,
            `Please provide valid information`
          );
      }
      if (
        rules.maxVal &&
        parseFloat(event.target.value) > rules.maxVal &&
        rules.restrict
      )
        event.target.value = rules.maxVal;

      if (rules.minVal && parseFloat(event.target.value) < rules.minVal)
        return this.setWarningMessage(
          event,
          `Minimum value has to be atleast ${rules.minVal}`
        );
    }
    if (rules.max && event.target.value.length > rules.max && rules.restrict)
      event.target.value = event.target.value.substr(0, rules.max);

    if (rules.min && event.target.value.length < rules.min)
      return this.setWarningMessage(
        event,
        `Minimum length has to be atleast ${rules.min} characters`
      );

    if (rules.email) {
      if (!this.emailValidatorPattern.test(event.target.value))
        return this.setWarningMessage(
          event,
          `Please provide valid email address`
        );
    }
    if (rules.pattern == 'num-space') {
      if (!this.numericAndSpace.test(event.target.value)) {
        if (rules.restrict)
          event.target.value = event.target.value.slice(0, -1);
        else
          return this.setWarningMessage(
            event,
            `Please provide valid information`
          );
      }
    }
  }

  public validateInput(target: any, control: any): void {
    let element = target.parentElement;
    if (target.parentElement.classList.contains('input-group')) {
      element = target.parentElement.parentElement;
    }
    let span = element.getElementsByTagName('span')[0];
    const label = element.getElementsByTagName('label')[0];

    if (span) {
      element.removeChild(span);
    }
    if (
      control.invalid &&
      (control.dirty || control.touched || target.type === 'checkbox')
    ) {
      let msg = '';
      if (label) {
        msg = label.innerHTML;
      } else {
        msg = target.placeholder;
      }
      if (control.errors.required) {
        msg += ' is required.';
      } else if (control.errors.pattern) {
        msg = 'Invalid ' + msg + '.';
      } else if (control.errors.minlength) {
        msg =
          msg +
          ' minimum ' +
          control.errors.minlength.requiredLength +
          ' characters.';
      } else if (control.errors.maxlength) {
        msg =
          msg +
          ' maximum ' +
          control.errors.maxlength.requiredLength +
          ' characters.';
      } else if (control.errors.max) {
        msg = msg + ' maximum ' + control.errors.max.max;
      } else if (control.errors.min) {
        msg = msg + ' minimum ' + control.errors.min.min;
      }

      span = document.createElement('span');
      span.setAttribute('class', 'msg');
      span.classList.add('error-label');
      span.innerHTML = msg;
      element.appendChild(span);
    }
  }

  setWarningMessage(event, message) {
    let warningSpan = document.createElement('small');
    warningSpan.style.color = 'Red';
    warningSpan.classList.add('warning-input');
    warningSpan.style.fontWeight = '500';
    warningSpan.style.fontSize = '8pt';
    warningSpan.innerHTML = message;
    event.currentTarget.parentNode.appendChild(warningSpan);
  }

  logout() {
    this.eventMqtt.unSubscribeTopic(
      this._topics.topicRideAccept.replace(
        '+',
        this.localStorage.get('auth').user.id
      )
    );
    this.eventMqtt.rideAcceptSubscribed(false);
    if (this.eventMqtt.currentlyActivateRideRequestId) {
      this.eventMqtt.unSubscribeTopic(
        this._topics.topicRideStatus.replace(
          '+',
          this.eventMqtt.currentlyActivateRideRequestId
        )
      );
      this.eventMqtt.unSubscribeTopic(
        this._topics.topicRideProgress.replace(
          '+',
          this.eventMqtt.currentlyActivateRideRequestId
        )
      );
    }
    this._accountS.setIsLogin(false);
    this.router.navigateByUrl('/');
    this.localStorage.remove('auth');
  }

  goHome() {
    this.router.navigateByUrl('/');
  }

  showNotification(header, message, status = 200, timeout = 3000) {
    (document.getElementById('notificaitonHeader') as HTMLElement).innerHTML =
      header;
    (document.getElementById('notificationMessage') as HTMLElement).innerHTML =
      message;
    if (status == 200) {
      (
        document.getElementById('generalNotification') as HTMLDivElement
      ).style.backgroundColor = '#ffc112';
      if (
        (
          document.getElementById('notificationIcon') as HTMLElement
        ).classList.contains('fa-question-circle')
      )
        (
          document.getElementById('notificationIcon') as HTMLElement
        ).classList.remove('fa-question-circle');
      if (
        (
          document.getElementById('notificationIcon') as HTMLElement
        ).classList.contains('fa-exclamation-triangle')
      )
        (
          document.getElementById('notificationIcon') as HTMLElement
        ).classList.remove('fa-exclamation-triangle');

      (
        document.getElementById('notificationIcon') as HTMLElement
      ).classList.add('fa-check');
    } else if (status == 300) {
      (
        document.getElementById('generalNotification') as HTMLDivElement
      ).style.backgroundColor = '#8f6e32';
      if (
        (
          document.getElementById('notificationIcon') as HTMLElement
        ).classList.contains('fa-check')
      )
        (
          document.getElementById('notificationIcon') as HTMLElement
        ).classList.remove('fa-check');
      if (
        (
          document.getElementById('notificationIcon') as HTMLElement
        ).classList.contains('fa-exclamation-triangle')
      )
        (
          document.getElementById('notificationIcon') as HTMLElement
        ).classList.remove('fa-exclamation-triangle');

      (
        document.getElementById('notificationIcon') as HTMLElement
      ).classList.add('fa-question-circle');
    } else {
      (
        document.getElementById('generalNotification') as HTMLDivElement
      ).style.backgroundColor = '#8f6e32';
      if (
        (
          document.getElementById('notificationIcon') as HTMLElement
        ).classList.contains('fa-check')
      )
        (
          document.getElementById('notificationIcon') as HTMLElement
        ).classList.remove('fa-check');
      if (
        (
          document.getElementById('notificationIcon') as HTMLElement
        ).classList.contains('fa-question-circle')
      )
        (
          document.getElementById('notificationIcon') as HTMLElement
        ).classList.remove('fa-question-circle');

      (
        document.getElementById('generalNotification') as HTMLDivElement
      ).style.backgroundColor = 'red';
      (
        document.getElementById('notificationIcon') as HTMLElement
      ).classList.add('fa-exclamation-triangle');
    }

    (
      document.getElementById('generalNotification') as HTMLDivElement
    ).classList.add('load');

    setTimeout(() => {
      this.hideNotification();
    }, timeout);
  }

  hideNotification() {
    (
      document.getElementById('generalNotification') as HTMLDivElement
    ).classList.remove('load');
  }

  saveFcmtoken() {
    return new Promise(async (res, rej) => {
      const fcmToken = await this.fcmMessagingService.requestPermission();
      const fcmTokenSaved = await this._accountS
      .saveFcmToken(fcmToken)
        .catch((x) => console.log('Unable to save fcm token'));
        if (fcmTokenSaved && fcmTokenSaved['id']) {
        console.log(`Fcm token saved successfully: ${fcmTokenSaved['token']}`);
        }

      res(true);
    });
  }
// Stripe

 // strip element language
 public stripLanguage = {
  locale: 'en',
};

public stripOption = {
  style: {
    base: {
      iconColor: '#000',
      color: '#000',
      fontWeight: '300',
      fontSize: '14px',
      '::placeholder': {
        color: '#757575',
        fontSize: '14px',
      },
    },
  },
};

public popupModalWithOKbtn(message: string, header: string = null, status: number = 200) {
  // 200 = success, 300 = warning, 400 = error
  return alert({
    title: header !== null ? header : message,
    text: header !== null ? message : ""
  });
}

public requiredFieldInfoMessage() {
  return alert({
    text: 'Please fill mandatory fields',
  });
}

public async markFormAsFoucus() {
  for (let i = 0; i < document.getElementsByClassName("required").length; i++) {
    const el = (document.getElementsByClassName("required")[i] as HTMLElement);
    console.log(el)
    el.focus();
    await this.showError(el);
  }
}
public showError(el: any) {
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      el.blur();
      resolve(true);
    }, 5)
  })
}
}
